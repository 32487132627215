import { addDays, subDays, subHours, subMinutes } from "date-fns";
import { mock } from "../lib/axios";

const now = new Date();

mock.onGet("/api/deals").reply(() => {
  const projects = [
    {
      id: "7b9a19da64b0466b8b1d06c7de830e9b",
      author: {
        id: "5e887b7602bdbc4dbb234b27",
        avatar:
          "https://firebasestorage.googleapis.com/v0/b/creatordash-a90d0.appspot.com/o/logos%2Fjakeala_logo.webp?alt=media&token=32f7da2d-206f-4ec1-a554-1121d5b574df",
        name: "Jakeala",
      },
      caption:
        "We're seeking ladies to do gifted or paid collabs with! $25-$50 💕 We are looking for TikTok influencers 5,000+ followers especially engaging accounts. You will receive 1 product.",
      description: `
Hi 🙋‍♀️ All! 

We’re seeking ladies to do gifted or paid collabs with! $25-$50 💕 We are looking for TikTok influencers 5,000+ followers especially engaging accounts. You will receive 1 product.

We sell beauty products and currently seeking promotion on our feminine care products below:

Pineapple Cleansing spray - https://jakeala.net/sweet-refreshing-spray/
Pineapple Body Scrub - https://jakeala.net/turmeric-pineapple-body-scrub/
CBD Pineapple Body Glaze - https://jakeala.net/pineapple-body-glaze/

We are seeking a funny relatable video content. Simple videos are awesome also. 

Instagram: https://www.instagram.com/jakealausa 

Website: www.jakeala.net 

Please apply if you’re interested!

Thank you!
      `,
      likes: 25,
      reach: "Small (1k)",
      type: "Advertising",
      category: "Beauty",
      image:
        "https://firebasestorage.googleapis.com/v0/b/creatordash-a90d0.appspot.com/o/promo_pictures%2Fjakeala_product.jpg?alt=media&token=f3634df3-9b3a-415d-8233-256da73a7427",
      rating: 4.8,
      status: "active",
      title: "Gifted or Paid collabs",
      platform: ["TikTok"],
      // followerMin: 5000,
      // followerMax: 100000,
      updatedAt: subDays(now, 19).getTime(),
      endDate: addDays(now, 14).getTime(),
      reviews: [
        {
          id: "5f0366cd843161f193ebadd4",
          author: {
            avatar: "/static/mock-images/avatars/avatar-marcus_finn.png",
            name: "Marcus Finn",
          },
          comment: "Great company, providing an awesome & easy to use product.",
          createdAt: subHours(now, 2).getTime(),
          value: 5,
        },
        {
          id: "to33twsyjphcfj55y3t07261",
          author: {
            avatar: "/static/mock-images/avatars/avatar-miron_vitold.png",
            name: "Miron Vitold",
          },
          comment:
            "I can only say good thing about this company, good payment and nice products. Everything as it should be.",
          createdAt: subHours(now, 2).getTime(),
          value: 3.7,
        },
        {
          id: "6z9dwxjzkqbmxuluxx2681jd",
          author: {
            avatar: "/static/mock-images/avatars/avatar-carson_darrin.png",
            name: "Carson Darrin",
          },
          comment:
            "I applied and they shipped the product right away. I am an influencer with 50k subs on Instagram.",
          createdAt: subHours(now, 2).getTime(),
          value: 4,
        },
      ],
      files: [
        {
          id: "5e8dd0721b9e0fab56d7238b",
          mimeType: "image/jpg",
          name: "prada.jpg",
          size: 1024 * 1024 * 0.5,
          url: "/static/mock-images/projects/prada_promo.jpeg",
        },
      ],
      tags: ["React JS"],
      collaborators: [
        {
          id: "5e887a62195cc5aef7e8ca5d",
          avatar: "/static/mock-images/avatars/pamela_reif.jpg",
          job: "Food, Fashion and Lifestyle",
          name: "Pamela Reif",
        },
        {
          id: "5e887ac47eed253091be10cb",
          avatar: "/static/mock-images/avatars/helen_owen.jpg",
          job: "Travel + Fashion",
          name: "Helen Owen",
        },
        {
          id: "5e887b7602bdbc4dbb234b27",
          avatar: "/static/mock-images/avatars/abbie_herbert.jpg",
          job: "Supporting Proud Mamas",
          name: "Abbie Herbert",
        },
      ],
    },
    {
      id: "5e8dcef8f95685ce21f16f3d",
      author: {
        id: "5e887b7602bdbc4dbb234b27",
        avatar: "/static/mock-images/avatars/prada_logo.png",
        name: "Prada",
      },
      caption:
        "We are looking for several small reach influencer that can promote our new summer bag collection (see link in description).",
      description: `
Our new summer collection has arrived!

For our bags, we are searching for a content creator that would like to promote our products.

In return, you will get free bags (shipping included) and a promotion code.

See one the right our collaborators that has already promoted previous products.

Your reach should be around 10k - 150k on Instagram.

Even if you don't fit all listed criteria, please apply and we will talk to your later. 

We are looking forward see your application for this deal! :)

Priscilla
      `,
      likes: 57,
      reach: "Mid (10k)",
      type: "Advertising",
      category: "Food",
      image: "/static/mock-images/projects/prada_promo.jpeg",
      rating: 4,
      status: "active",
      title: "Summer Bag Collection",
      platform: ["TikTok", "Instagram", "YouTube"],
      //platform: ["TikTok", "Instagram", "YouTube", "Blog", "Facebook"],
      // followerMin: 5000,
      // followerMax: 100000,
      updatedAt: subDays(now, 20).getTime(),
      endDate: addDays(now, 14).getTime(),
      reviews: [
        {
          id: "5f0366cd843161f193ebadd4",
          author: {
            avatar: "/static/mock-images/avatars/avatar-marcus_finn.png",
            name: "Marcus Finn",
          },
          comment: "Great company, providing an awesome & easy to use product.",
          createdAt: subHours(now, 2).getTime(),
          value: 5,
        },
        {
          id: "to33twsyjphcfj55y3t07261",
          author: {
            avatar: "/static/mock-images/avatars/avatar-miron_vitold.png",
            name: "Miron Vitold",
          },
          comment:
            "I can only say good thing about this company, good payment and nice products. Everything as it should be.",
          createdAt: subHours(now, 2).getTime(),
          value: 3.7,
        },
        {
          id: "6z9dwxjzkqbmxuluxx2681jd",
          author: {
            avatar: "/static/mock-images/avatars/avatar-carson_darrin.png",
            name: "Carson Darrin",
          },
          comment:
            "I applied and they shipped the product right away. I am an influencer with 50k subs on Instagram.",
          createdAt: subHours(now, 2).getTime(),
          value: 4,
        },
      ],
      files: [
        {
          id: "5e8dd0721b9e0fab56d7238b",
          mimeType: "image/jpg",
          name: "prada.jpg",
          size: 1024 * 1024 * 0.5,
          url: "/static/mock-images/projects/prada_promo.jpeg",
        },
      ],
      tags: ["React JS"],
      collaborators: [
        {
          id: "5e887a62195cc5aef7e8ca5d",
          avatar: "/static/mock-images/avatars/pamela_reif.jpg",
          job: "Food, Fashion and Lifestyle",
          name: "Pamela Reif",
        },
        {
          id: "5e887ac47eed253091be10cb",
          avatar: "/static/mock-images/avatars/helen_owen.jpg",
          job: "Travel + Fashion",
          name: "Helen Owen",
        },
        {
          id: "5e887b7602bdbc4dbb234b27",
          avatar: "/static/mock-images/avatars/abbie_herbert.jpg",
          job: "Supporting Proud Mamas",
          name: "Abbie Herbert",
        },
      ],
    },
    {
      id: "5e8dcf076c50b9d8e756a5a2",
      author: {
        id: "5e887d0b3d090c1b8f162003",
        avatar: "/static/mock-images/avatars/na_kd_logo.png",
        name: "Na-Kd",
      },
      caption: "We provide Promotion Codes & More. All items are free for you!",
      description: `
Dear influencer,

We are currently searching for several mid reach influencer that using promotions links for promoting their products.

The promoted products are your choice and for each buy, you will receive a margin. 

We are looking forward see your application for this deal! :)

Jane

      `,
      likes: 12,
      reach: "Mid (10k)",
      type: "Advertising",
      category: "Food",
      image: "/static/mock-images/projects/na_kd_promo.jpg",
      rating: 4.5,
      status: "active",
      title: "Sponsoring for high volumne influencer",
      platform: ["Instagram", "TikTok"],
      updatedAt: subDays(now, 22).getTime(),
      endDate: addDays(now, 14).getTime(),
      reviews: [
        {
          id: "5f0366cd843161f193ebadd4",
          author: {
            avatar: "/static/mock-images/avatars/avatar-marcus_finn.png",
            name: "Marcus Finn",
          },
          comment: "Great company, providing an awesome & easy to use product.",
          createdAt: subHours(now, 2).getTime(),
          value: 5,
        },
        {
          id: "to33twsyjphcfj55y3t07261",
          author: {
            avatar: "/static/mock-images/avatars/avatar-miron_vitold.png",
            name: "Miron Vitold",
          },
          comment:
            "I can only say good thing about this company, good payment and nice products. Everything as it should be.",
          createdAt: subHours(now, 2).getTime(),
          value: 3.7,
        },
        {
          id: "6z9dwxjzkqbmxuluxx2681jd",
          author: {
            avatar: "/static/mock-images/avatars/avatar-carson_darrin.png",
            name: "Carson Darrin",
          },
          comment:
            "I applied and they shipped the product right away. I am an influencer with 50k subs on Instagram.",
          createdAt: subHours(now, 2).getTime(),
          value: 4,
        },
      ],
      files: [
        {
          id: "5e8dd0721b9e0fab56d7238b",
          mimeType: "image/jpg",
          name: "na_kd_promo.jpg",
          size: 1024 * 1024 * 0.5,
          url: "/static/mock-images/projects/na_kd_promo.jpg",
        },
      ],
      tags: ["React JS"],
      collaborators: [
        {
          id: "5e887a62195cc5aef7e8ca5d",
          avatar: "/static/mock-images/avatars/pamela_reif.jpg",
          job: "Food, Fashion and Lifestyle",
          name: "Pamela Reif",
        },
        {
          id: "5e887ac47eed253091be10cb",
          avatar: "/static/mock-images/avatars/helen_owen.jpg",
          job: "Travel + Fashion",
          name: "Helen Owen",
        },
        {
          id: "5e887b7602bdbc4dbb234b27",
          avatar: "/static/mock-images/avatars/abbie_herbert.jpg",
          job: "Supporting Proud Mamas",
          name: "Abbie Herbert",
        },
      ],
    },
    {
      id: "5e8dcf105a6732b3ed82cf7a",
      author: {
        id: "5e88792be2d4cfb4bf0971d9",
        avatar: "/static/mock-images/avatars/loreal_logo.png",
        name: "L`Oreal",
      },
      caption: "Review for Serie Expert searched",
      description: `
Hello everybody,

we are looking for an content creator (any platform) that want to use our Serie Expert (see details).

The review is up to you can be shared everywhere. 

We are happy for negotiate the benefits for you!

See you, soon!

L'Oreal

      `,
      likes: 44,
      reach: "Small (1k)",
      type: "Review",
      category: "Food",
      image: "/static/mock-images/projects/loreal_promo.jpg",
      rating: 4.7,
      status: "active",
      title: "Serie Expert Promotion Code",
      platform: ["Instagram", "TikTok", "YouTube", "Blog", "Facebook"],
      updatedAt: subDays(now, 30).getTime(),
      endDate: addDays(now, 14).getTime(),
      reviews: [
        {
          id: "5f0366cd843161f193ebadd4",
          author: {
            avatar: "/static/mock-images/avatars/avatar-marcus_finn.png",
            name: "Marcus Finn",
          },
          comment: "Great company, providing an awesome & easy to use product.",
          createdAt: subHours(now, 2).getTime(),
          value: 5,
        },
        {
          id: "to33twsyjphcfj55y3t07261",
          author: {
            avatar: "/static/mock-images/avatars/avatar-miron_vitold.png",
            name: "Miron Vitold",
          },
          comment:
            "I can only say good thing about this company, good payment and nice products. Everything as it should be.",
          createdAt: subHours(now, 2).getTime(),
          value: 3.7,
        },
        {
          id: "6z9dwxjzkqbmxuluxx2681jd",
          author: {
            avatar: "/static/mock-images/avatars/avatar-carson_darrin.png",
            name: "Carson Darrin",
          },
          comment:
            "I applied and they shipped the product right away. I am an influencer with 50k subs on Instagram.",
          createdAt: subHours(now, 2).getTime(),
          value: 4,
        },
      ],
      files: [
        {
          id: "5e8dd0721b9e0fab56d7238b",
          mimeType: "image/jpg",
          name: "loreal_promo.jpg",
          size: 1024 * 1024 * 0.5,
          url: "/static/mock-images/projects/loreal_promo.jpg",
        },
      ],
      tags: ["React JS"],
      collaborators: [
        {
          id: "5e887a62195cc5aef7e8ca5d",
          avatar: "/static/mock-images/avatars/pamela_reif.jpg",
          job: "Food, Fashion and Lifestyle",
          name: "Pamela Reif",
        },
        {
          id: "5e887ac47eed253091be10cb",
          avatar: "/static/mock-images/avatars/helen_owen.jpg",
          job: "Travel + Fashion",
          name: "Helen Owen",
        },
        {
          id: "5e887b7602bdbc4dbb234b27",
          avatar: "/static/mock-images/avatars/abbie_herbert.jpg",
          job: "Supporting Proud Mamas",
          name: "Abbie Herbert",
        },
      ],
    },
    {
      id: "5e8dcf1cc7155d0e947dc27f",
      author: {
        id: "5e8877da9a65442b11551975",
        avatar: "/static/mock-images/avatars/bruno_banani_logo.jpg",
        name: "Bruno Banani",
      },
      caption: "Review for Bruno Banani products (of your choice).",
      description: `
Hello,

we are looking for an content creator (any platform) that want to use our Serie Expert (see details).

The review is up to you can be shared everywhere. 

We are happy for negotiate the benefits for you!

See you, soon!

Lisa from the Bruno Banani Team
            `,
      image: "/static/mock-images/projects/bruno_banani_promo.webp",
      likes: 6,
      reach: "Small (1k)",
      type: "Advertising",
      category: "Food",
      rating: 3.9,
      status: "active",
      title: "Man`s Best Deodorant Spray",
      platform: ["Instagram", "TikTok"],
      updatedAt: subDays(now, 20).getTime(),
      endDate: addDays(now, 14).getTime(),
      reviews: [
        {
          id: "5f0366cd843161f193ebadd4",
          author: {
            avatar: "/static/mock-images/avatars/avatar-marcus_finn.png",
            name: "Marcus Finn",
          },
          comment: "Great company, providing an awesome & easy to use product.",
          createdAt: subHours(now, 2).getTime(),
          value: 5,
        },
        {
          id: "to33twsyjphcfj55y3t07261",
          author: {
            avatar: "/static/mock-images/avatars/avatar-miron_vitold.png",
            name: "Miron Vitold",
          },
          comment:
            "I can only say good thing about this company, good payment and nice products. Everything as it should be.",
          createdAt: subHours(now, 2).getTime(),
          value: 3.7,
        },
        {
          id: "6z9dwxjzkqbmxuluxx2681jd",
          author: {
            avatar: "/static/mock-images/avatars/avatar-carson_darrin.png",
            name: "Carson Darrin",
          },
          comment:
            "I applied and they shipped the product right away. I am an influencer with 50k subs on Instagram.",
          createdAt: subHours(now, 2).getTime(),
          value: 4,
        },
      ],
      files: [
        {
          id: "5e8dd0721b9e0fab56d7238b",
          mimeType: "image/jpg",
          name: "bruno_banani_promo.webp",
          size: 1024 * 1024 * 0.5,
          url: "/static/mock-images/projects/bruno_banani_promo.webp",
        },
      ],
      collaborators: [
        {
          id: "5e887a62195cc5aef7e8ca5d",
          avatar: "/static/mock-images/avatars/pamela_reif.jpg",
          job: "Food, Fashion and Lifestyle",
          name: "Pamela Reif",
        },
        {
          id: "5e887ac47eed253091be10cb",
          avatar: "/static/mock-images/avatars/helen_owen.jpg",
          job: "Travel + Fashion",
          name: "Helen Owen",
        },
        {
          id: "5e887b7602bdbc4dbb234b27",
          avatar: "/static/mock-images/avatars/abbie_herbert.jpg",
          job: "Supporting Proud Mamas",
          name: "Abbie Herbert",
        },
      ],
    },
    {
      id: "5e8dcf252313876001e83221",
      author: {
        id: "5e887ac47eed253091be10cb",
        avatar: "/static/mock-images/avatars/paco_rabanne_logo.png",
        name: "Paco Rabanne",
      },
      caption: "Content Creator for perfumes collection needed",
      description: `
Hello! Paco Rabanne here.

We need an amazing content creator (mainly Instagram) that wanna try our perfumes.

We are happy for negotiate other benefits for you!

See you, soon!

Josefine
`,
      likes: 650,
      reach: "Small (1k)",
      type: "Advertising",
      category: "Food",
      image: "/static/mock-images/projects/paco_rabanne_promo.webp",
      rating: 4.9,
      status: "active",
      title: "1 Million Eau de Toilette Spray",
      platform: ["Instagram"],
      updatedAt: subDays(now, 27).getTime(),
      endDate: addDays(now, 14).getTime(),
      reviews: [
        {
          id: "5f0366cd843161f193ebadd4",
          author: {
            avatar: "/static/mock-images/avatars/avatar-marcus_finn.png",
            name: "Marcus Finn",
          },
          comment: "Great company, providing an awesome & easy to use product.",
          createdAt: subHours(now, 2).getTime(),
          value: 5,
        },
        {
          id: "to33twsyjphcfj55y3t07261",
          author: {
            avatar: "/static/mock-images/avatars/avatar-miron_vitold.png",
            name: "Miron Vitold",
          },
          comment:
            "I can only say good thing about this company, good payment and nice products. Everything as it should be.",
          createdAt: subHours(now, 2).getTime(),
          value: 3.7,
        },
        {
          id: "6z9dwxjzkqbmxuluxx2681jd",
          author: {
            avatar: "/static/mock-images/avatars/avatar-carson_darrin.png",
            name: "Carson Darrin",
          },
          comment:
            "I applied and they shipped the product right away. I am an influencer with 50k subs on Instagram.",
          createdAt: subHours(now, 2).getTime(),
          value: 4,
        },
      ],
      files: [
        {
          id: "5e8dd0721b9e0fab56d7238b",
          mimeType: "image/jpg",
          name: "paco_rabanne_promo.webp",
          size: 1024 * 1024 * 0.5,
          url: "/static/mock-images/projects/paco_rabanne_promo.webp",
        },
      ],
      tags: ["React JS"],
      collaborators: [
        {
          id: "5e887a62195cc5aef7e8ca5d",
          avatar: "/static/mock-images/avatars/pamela_reif.jpg",
          job: "Food, Fashion and Lifestyle",
          name: "Pamela Reif",
        },
        {
          id: "5e887ac47eed253091be10cb",
          avatar: "/static/mock-images/avatars/helen_owen.jpg",
          job: "Travel + Fashion",
          name: "Helen Owen",
        },
        {
          id: "5e887b7602bdbc4dbb234b27",
          avatar: "/static/mock-images/avatars/abbie_herbert.jpg",
          job: "Supporting Proud Mamas",
          name: "Abbie Herbert",
        },
      ],
    },
    {
      id: "5e8dcf4250d77c954b04902e",
      author: {
        id: "5e887b7602bdbc4dbb234b27",
        avatar: "/static/mock-images/avatars/squarespace_logo.png",
        name: "Squarespace",
      },
      caption: "Website promotion through Ads (YoutTube) software.",
      description: `
Dear Content Creator,

we are happy that you found your way here and obviously, we are searching for an influencer to promote our website.

I am looking forward to your application!

David
      `,
      likes: 35,
      reach: "Large (100k)",
      type: "Advertising",
      category: "Other",
      image: "/static/mock-images/projects/squarespace_promo.jpg",
      rating: 4.4,
      status: "active",
      title: "Squarespace Ad",
      platform: ["YouTube"],
      updatedAt: subDays(now, 17).getTime(),
      endDate: addDays(now, 14).getTime(),
      reviews: [
        {
          id: "5f0366cd843161f193ebadd4",
          author: {
            avatar: "/static/mock-images/avatars/avatar-marcus_finn.png",
            name: "Marcus Finn",
          },
          comment: "Great company, providing an awesome & easy to use product.",
          createdAt: subHours(now, 2).getTime(),
          value: 5,
        },
        {
          id: "to33twsyjphcfj55y3t07261",
          author: {
            avatar: "/static/mock-images/avatars/avatar-miron_vitold.png",
            name: "Miron Vitold",
          },
          comment:
            "I can only say good thing about this company, good payment and nice products. Everything as it should be.",
          createdAt: subHours(now, 2).getTime(),
          value: 3.7,
        },
        {
          id: "6z9dwxjzkqbmxuluxx2681jd",
          author: {
            avatar: "/static/mock-images/avatars/avatar-carson_darrin.png",
            name: "Carson Darrin",
          },
          comment:
            "I applied and they shipped the product right away. I am an influencer with 50k subs on Instagram.",
          createdAt: subHours(now, 2).getTime(),
          value: 4,
        },
      ],
      files: [
        {
          id: "5e8dd0721b9e0fab56d7238b",
          mimeType: "image/jpg",
          name: "squarespace_promo.jpg",
          size: 1024 * 1024 * 0.5,
          url: "/static/mock-images/projects/squarespace_promo.jpg",
        },
      ],
      tags: ["React JS"],
      collaborators: [
        {
          id: "5e887a62195cc5aef7e8ca5d",
          avatar: "/static/mock-images/avatars/pamela_reif.jpg",
          job: "Food, Fashion and Lifestyle",
          name: "Pamela Reif",
        },
        {
          id: "5e887ac47eed253091be10cb",
          avatar: "/static/mock-images/avatars/helen_owen.jpg",
          job: "Travel + Fashion",
          name: "Helen Owen",
        },
        {
          id: "5e887b7602bdbc4dbb234b27",
          avatar: "/static/mock-images/avatars/abbie_herbert.jpg",
          job: "Supporting Proud Mamas",
          name: "Abbie Herbert",
        },
      ],
    },
    {
      id: "e23588a5f66149999b94a037b7724460",
      status: "active",
      title: "Dior Forever Nude",
      caption: "Product Placement through Instagram.",
      reach: "Large (100k)",
      type: "Product Placement",
      category: "Beauty",
      platform: ["Instagram"],
      author: {
        id: "5e887b7602bdbc4dbb234b27",
        avatar: "/static/mock-images/avatars/dior_logo.png",
        name: "Dior",
      },
      image: "/static/mock-images/projects/dior_forever.jpg",
      description: `
Hello everybody!

For our new collection "Forever Nude", we are searching a large reach influencer. We are happy to review your application!

https://www.instagram.com/p/CL-B98rIHPF/?utm_source=ig_web_copy_link


New Dior Forever Natural Nude features 24-hour wear with 96% natural ingredients in 17 skin-enhancing shades. A lighter version of the iconic Forever and Forever Skin Glow foundation. Get yours!
Exclusive to select markets.
•
DIOR FOREVER NATURAL NUDE
•
#diormakeup #diorforever #lovemydiorskin #foreversisterhood


Celine
      `,
      likes: 450,
      rating: 4.8,
      updatedAt: subDays(now, 15).getTime(),
      endDate: addDays(now, 14).getTime(),
      reviews: [
        {
          id: "5f0366cd843161f193ebadd4",
          author: {
            avatar: "/static/mock-images/avatars/avatar-marcus_finn.png",
            name: "Marcus Finn",
          },
          comment: "Great company, providing an awesome & easy to use product.",
          createdAt: subHours(now, 2).getTime(),
          value: 5,
        },
        {
          id: "to33twsyjphcfj55y3t07261",
          author: {
            avatar: "/static/mock-images/avatars/avatar-miron_vitold.png",
            name: "Miron Vitold",
          },
          comment:
            "I can only say good thing about this company, good payment and nice products. Everything as it should be.",
          createdAt: subHours(now, 2).getTime(),
          value: 3.7,
        },
        {
          id: "6z9dwxjzkqbmxuluxx2681jd",
          author: {
            avatar: "/static/mock-images/avatars/avatar-carson_darrin.png",
            name: "Carson Darrin",
          },
          comment:
            "I applied and they shipped the product right away. I am an influencer with 50k subs on Instagram.",
          createdAt: subHours(now, 2).getTime(),
          value: 4,
        },
      ],
      collaborators: [
        {
          id: "5e887a62195cc5aef7e8ca5d",
          avatar: "/static/mock-images/avatars/pamela_reif.jpg",
          job: "Food, Fashion and Lifestyle",
          name: "Pamela Reif",
        },
        {
          id: "5e887ac47eed253091be10cb",
          avatar: "/static/mock-images/avatars/helen_owen.jpg",
          job: "Travel + Fashion",
          name: "Helen Owen",
        },
        {
          id: "5e887b7602bdbc4dbb234b27",
          avatar: "/static/mock-images/avatars/abbie_herbert.jpg",
          job: "Supporting Proud Mamas",
          name: "Abbie Herbert",
        },
      ],
      files: [
        {
          id: "5e8dd0721b9e0fab56d7238b",
          mimeType: "image/jpg",
          name: "dior_forever.jpg",
          size: 1024 * 1024 * 0.5,
          url: "/static/mock-images/projects/dior_forever.jpg",
        },
      ],
    },
    {
      id: "f020db51a65f47e5b76c19a461d2315f",
      status: "active",
      title: "Ambassador Wanted!",
      caption: "We are looking for ambassadors",
      reach: "Small (1k)",
      type: "Advertising",
      category: "Fashion",
      platform: ["Instagram"],
      author: {
        id: "5e887b7602bdbc4dbb234b27",
        avatar: "/static/mock-images/avatars/freya_logo.jpg",
        name: "Freya Treasures Jewerly",
      },
      image: "/static/mock-images/projects/freya_ambassador.jpg",
      files: [
        {
          id: "5e8dd0721b9e0fab56d7238b",
          mimeType: "image/jpg",
          name: "freya_ambassador.jpg",
          size: 1024 * 1024 * 0.5,
          url: "/static/mock-images/projects/freya_ambassador.jpg",
        },
      ],
      description: `
Hi! 👋 

I'm a Brand Manager [@freya.treasures](https://freyatreasures.com/) A Women's Fashion Jewelries. We are currently looking for Brand Ambassadors who are willing to represent our brand name, Freya.

I took a bit of time to check and review your profile & we are interested to sponsor you & give you 4items for FREE. ✨

If you want to earn 25$ commission and another $1,000 for Top ambassadors then you should definitely grab this opportunity!!

Products are made of High Quality Gold Plated and 100% Hypoallergenic. ❤️

Let me know if you're interested because this is a time limited offer!!!⏰⏰


Freya links:

[Our Website](https://freyatreasures.com/)

[Instagram](https://www.instagram.com/freya_good/)

      `,
      likes: 20,
      rating: 4.8,
      updatedAt: subDays(now, 22).getTime(),
      endDate: addDays(now, 14).getTime(),
      reviews: [],
      collaborators: [],
    },
    {
      id: "e23588a5f66149999b94a037b7724420",
      status: "active",
      title: "Travel Package",
      caption:
        "We are giving away a travel package for high-reach influencer group. Requirement is to mention our agency daily in your Instagram stories during your trip.",
      reach: "Large (100k)",
      type: "Product Placement",
      category: "Travel",
      platform: ["Instagram"],
      author: {
        id: "5e887b7602bdbc4dbb234b27",
        avatar: "/static/mock-images/avatars/bali_guest.jpg",
        name: "The Bali Guest",
      },
      image: "/static/mock-images/projects/bali_package.jpg",
      files: [
        {
          id: "5e8dd0721b9e0fab56d7238b",
          mimeType: "image/jpg",
          name: "bali_package.jpg",
          size: 1024 * 1024 * 0.5,
          url: "/static/mock-images/projects/bali_package.jpg",
        },
      ],
      description: `
Hey guys&gals!

Take a lil’ break and enjoy a sunny pool day 🏖️

Together with CreatorDash, we are giving away a Nusa Penida Trip 🌴, One day Bali Trip, Helicopter Trip, Sailing to Komodo Island, Fast boat to Gili Island, lombok trip and Private Yacht🛥️

Ubud hotels consist of a selection of the most relaxing retreats in Bali, thanks to its cool upland location with scenic mountain vistas and flowing green valleys as added bonuses, and being in the artistic and cultural heart of the island. They also come in a rich variety, from luxury villas with outdoor dip pools, resort grounds that were designed to mimic terraced rice fields, and those that promote wellbeing through their winning spa facilities.

Ideally, we are searching for a group of high reach influencer for this trip that will tag our Instagram profile several times a day in their stories 👇

So, please tag your friends and apply 🤝

See more here: [Instagram/thebaliguest](https://www.instagram.com/thebaliguest/)

Please follow [Instagram/CreatorDash](https://www.instagram.com/creatordash_app/) because they make this possible and keep you uptodate with the latest deals.

Update: Due to the high number of applications will not be able to get back to everyone. 

Thanks 🙏

      `,
      likes: 450,
      rating: 4.8,
      updatedAt: subDays(now, 20).getTime(),
      endDate: addDays(now, 14).getTime(),
      reviews: [
        {
          id: "5f0366cd843161f193ebadd4",
          author: {
            avatar: "/static/mock-images/avatars/avatar-marcus_finn.png",
            name: "Marcus Finn",
          },
          comment: "Great company, providing an awesome & easy to use product.",
          createdAt: subHours(now, 2).getTime(),
          value: 5,
        },
        {
          id: "to33twsyjphcfj55y3t07261",
          author: {
            avatar: "/static/mock-images/avatars/avatar-miron_vitold.png",
            name: "Miron Vitold",
          },
          comment:
            "I can only say good thing about this company, good payment and nice products. Everything as it should be.",
          createdAt: subHours(now, 2).getTime(),
          value: 3.7,
        },
        {
          id: "6z9dwxjzkqbmxuluxx2681jd",
          author: {
            avatar: "/static/mock-images/avatars/avatar-carson_darrin.png",
            name: "Carson Darrin",
          },
          comment:
            "I applied and they shipped the product right away. I am an influencer with 50k subs on Instagram.",
          createdAt: subHours(now, 2).getTime(),
          value: 4,
        },
      ],
      collaborators: [
        {
          id: "5e887a62195cc5aef7e8ca5d",
          avatar: "/static/mock-images/avatars/pamela_reif.jpg",
          job: "Food, Fashion and Lifestyle",
          name: "Pamela Reif",
        },
        {
          id: "5e887ac47eed253091be10cb",
          avatar: "/static/mock-images/avatars/helen_owen.jpg",
          job: "Travel + Fashion",
          name: "Helen Owen",
        },
        {
          id: "5e887b7602bdbc4dbb234b27",
          avatar: "/static/mock-images/avatars/abbie_herbert.jpg",
          job: "Supporting Proud Mamas",
          name: "Abbie Herbert",
        },
      ],
    },
    {
      id: "e23588a5f66149999b94a037b71237220",
      status: "active",
      title: "SCOUTING FOR INFLUENCERS",
      caption:
        "I'm looking for influencers who have interested in Bluetooth Speakers（Brand: Aomais)",
      reach: "Mid (10k)",
      type: "Advertising",
      category: "Other",
      platform: ["Instagram", "YouTube", "TikTok"],
      author: {
        id: "5e887b7602bdbc4dbb234b27",
        avatar:
          "https://firebasestorage.googleapis.com/v0/b/creatordash-a90d0.appspot.com/o/logos%2Faomais_logo.png?alt=media&token=d81d5130-7ada-4f2a-852d-145a607c8938",
        name: "Aomais",
      },
      image:
        "https://firebasestorage.googleapis.com/v0/b/creatordash-a90d0.appspot.com/o/promo_pictures%2Faomais_product.jpg?alt=media&token=d25e22f7-c31b-44e5-81a4-eeaf411237f8",
      files: [
        {
          id: "5e8dd0721b9e0fab56d7238b",
          mimeType: "image/jpg",
          name: "promotion_image.jpg",
          size: 1024 * 1024 * 0.5,
          url: "https://firebasestorage.googleapis.com/v0/b/creatordash-a90d0.appspot.com/o/promo_pictures%2Faomais_product.jpg?alt=media&token=d25e22f7-c31b-44e5-81a4-eeaf411237f8",
        },
      ],
      description: `
SCOUTING FOR INFLUENCERS
I’m looking for influencers who have interested in Bluetooth Speakers（Brand: Aomais)
Platforms: Instagram, YouTube, Tiktok
1. Number of followers 10k +
2. The audience is interested in electronic products
3.From US
Cooperation:
1. Can discuss cooperation commission
2. I’ll provide products for free
Please apply with "Get this deal" above if you're interested!

      `,
      likes: 450,
      rating: 4.8,
      updatedAt: subDays(now, 25).getTime(),
      endDate: addDays(now, 14).getTime(),
      reviews: [
        {
          id: "5f0366cd843161f193ebadd4",
          author: {
            avatar: "/static/mock-images/avatars/avatar-marcus_finn.png",
            name: "Marcus Finn",
          },
          comment: "Great company, providing an awesome & easy to use product.",
          createdAt: subHours(now, 2).getTime(),
          value: 5,
        },
        {
          id: "to33twsyjphcfj55y3t07261",
          author: {
            avatar: "/static/mock-images/avatars/avatar-miron_vitold.png",
            name: "Miron Vitold",
          },
          comment:
            "I can only say good thing about this company, good payment and nice products. Everything as it should be.",
          createdAt: subHours(now, 2).getTime(),
          value: 3.7,
        },
        {
          id: "6z9dwxjzkqbmxuluxx2681jd",
          author: {
            avatar: "/static/mock-images/avatars/avatar-carson_darrin.png",
            name: "Carson Darrin",
          },
          comment:
            "I applied and they shipped the product right away. I am an influencer with 50k subs on Instagram.",
          createdAt: subHours(now, 2).getTime(),
          value: 4,
        },
      ],
      collaborators: [],
    },
    {
      id: "e23588a5f66149999b94a037b77220",
      status: "active",
      title: "Gifted collab for ladies",
      caption:
        "We’re looking for ladies to do gifted collabs with! 💕 We are looking for influencers (both Tiktok and Instagram) who specialize in beauty, makeup, hair and/or lifestyle and have 8,000+ followers.",
      reach: "Mid (10k)",
      type: "Advertising",
      category: "Beauty",
      platform: ["Instagram", "TikTok"],
      author: {
        id: "5e887b7602bdbc4dbb234b27",
        avatar:
          "https://firebasestorage.googleapis.com/v0/b/creatordash-a90d0.appspot.com/o/logos%2Fsilkyy_logo.jpg?alt=media&token=44db1284-df75-4caf-b234-79b491b3cb13",
        name: "Silkyy",
      },
      image:
        "https://firebasestorage.googleapis.com/v0/b/creatordash-a90d0.appspot.com/o/promo_pictures%2Fsilkyy_product.jpg?alt=media&token=764d28cc-ab83-4b48-82cc-fa84484127a8",
      files: [
        {
          id: "5e8dd0721b9e0fab56d7238b",
          mimeType: "image/jpg",
          name: "promotion_image.jpg",
          size: 1024 * 1024 * 0.5,
          url: "https://firebasestorage.googleapis.com/v0/b/creatordash-a90d0.appspot.com/o/promo_pictures%2Fsilkyy_product.jpg?alt=media&token=764d28cc-ab83-4b48-82cc-fa84484127a8",
        },
      ],
      description: `
Hi Everyone! 

We’re looking for ladies to do gifted collabs with! 💕 We are looking for influencers (both Tiktok and Instagram) who specialize in beauty, makeup, hair and/or lifestyle and have 8,000+ followers. 

We sell heatless hair curlers and are getting quite a bit of traction. The more creative the content, the better! Simple result videos are great as well and we are confident our product will work on almost all hair types. 

You can learn more about us on our social media (Shopsilkyy across all platforms but most active on Instagram) or on our website. 

[Instagram/shopsilkyy](https://www.instagram.com/shopsilkyy)

Website: [https://www.Shopsilkyy.com](https://www.Shopsilkyy.com)

Please apply with "Get this deal" above if you're interested!

If you have a ton of followers, we are also open to discussing paid collabs. 

Thank you!

      `,
      likes: 30,
      rating: 4.8,
      updatedAt: subDays(now, 15).getTime(),
      endDate: addDays(now, 14).getTime(),
      reviews: [
        {
          id: "5f0366cd843161f193ebadd4",
          author: {
            avatar: "/static/mock-images/avatars/avatar-marcus_finn.png",
            name: "Marcus Finn",
          },
          comment: "Great company, providing an awesome & easy to use product.",
          createdAt: subHours(now, 2).getTime(),
          value: 5,
        },
        {
          id: "to33twsyjphcfj55y3t07261",
          author: {
            avatar: "/static/mock-images/avatars/avatar-miron_vitold.png",
            name: "Miron Vitold",
          },
          comment:
            "I can only say good thing about this company, good payment and nice products. Everything as it should be.",
          createdAt: subHours(now, 2).getTime(),
          value: 3.7,
        },
        {
          id: "6z9dwxjzkqbmxuluxx2681jd",
          author: {
            avatar: "/static/mock-images/avatars/avatar-carson_darrin.png",
            name: "Carson Darrin",
          },
          comment:
            "I applied and they shipped the product right away. I am an influencer with 50k subs on Instagram.",
          createdAt: subHours(now, 2).getTime(),
          value: 4,
        },
      ],
      collaborators: [],
    },
    {
      id: "e23588a5f649999b94a037b77220",
      status: "active",
      title: "Gifted collab for micro-influencers",
      caption:
        "Hi everyone. I am looking for micro-influencers who would be interested in gifted collaboration. My product is: Sonic Facial Cleansing",
      reach: "Small (1k)",
      type: "Advertising",
      category: "Beauty",
      platform: ["Instagram", "TikTok"],
      author: {
        id: "5e887b7602bdbc4dbb234b27",
        avatar:
          "https://firebasestorage.googleapis.com/v0/b/creatordash-a90d0.appspot.com/o/logos%2Fskinsparq_logo.jpg?alt=media&token=af78b32d-e098-407e-abd5-faa035bd05d2",
        name: "Skinsparq",
      },
      image:
        "https://firebasestorage.googleapis.com/v0/b/creatordash-a90d0.appspot.com/o/promo_pictures%2Fskinsparq_product.jpg?alt=media&token=0d3287ec-cd37-4e85-bb93-fb81e7b7df97",
      files: [
        {
          id: "5e8dd0721b9e0fab56d7238b",
          mimeType: "image/jpg",
          name: "promotion_image.jpg",
          size: 1024 * 1024 * 0.5,
          url: "https://firebasestorage.googleapis.com/v0/b/creatordash-a90d0.appspot.com/o/promo_pictures%2Fskinsparq_product.jpg?alt=media&token=0d3287ec-cd37-4e85-bb93-fb81e7b7df97",
        },
      ],
      description: `
Hi everyone. I am looking for micro-influencers who would be interested in gifted collaboration. 

My product is: Sonic Facial Cleansing Brush + Spa Headband. 

The requirements: 
  - 5000 - 15000 followers
  - More than 3% engagement rate (important!)
  - Location: US, AU or EU 
  
If you have a ton of followers, we are also open to discussing paid collabs. 
  
Please apply with "Get this deal" above if you're interested!

Thank you!
      `,
      likes: 20,
      rating: 4.8,
      updatedAt: subDays(now, 16).getTime(),
      endDate: addDays(now, 14).getTime(),
      reviews: [
        {
          id: "5f0366cd843161f193ebadd4",
          author: {
            avatar: "/static/mock-images/avatars/avatar-marcus_finn.png",
            name: "Marcus Finn",
          },
          comment: "Great company, providing an awesome & easy to use product.",
          createdAt: subHours(now, 2).getTime(),
          value: 5,
        },
        {
          id: "to33twsyjphcfj55y3t07261",
          author: {
            avatar: "/static/mock-images/avatars/avatar-miron_vitold.png",
            name: "Miron Vitold",
          },
          comment:
            "I can only say good thing about this company, good payment and nice products. Everything as it should be.",
          createdAt: subHours(now, 2).getTime(),
          value: 3.7,
        },
        {
          id: "6z9dwxjzkqbmxuluxx2681jd",
          author: {
            avatar: "/static/mock-images/avatars/avatar-carson_darrin.png",
            name: "Carson Darrin",
          },
          comment:
            "I applied and they shipped the product right away. I am an influencer with 50k subs on Instagram.",
          createdAt: subHours(now, 2).getTime(),
          value: 4,
        },
      ],
      collaborators: [],
    },
  ];

  return [200, { projects }];
});
