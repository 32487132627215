import { analytics } from "lib/firebase";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SoonAvailable from "../components/widgets/modals/SoonAvailable";
import useAuth from "../hooks/useAuth";

const SoonAvailableGuard = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  // useEffect(() => {
  //   analytics.logEvent("page_view"+location.pathname);
  // }, []);


  if (location.pathname !== requestedLocation) {
    setRequestedLocation(location.pathname);
  }

  return <SoonAvailable title={location.pathname}/>;
};

SoonAvailableGuard.propTypes = {
  children: PropTypes.node,
};

export default SoonAvailableGuard;
