import {
  Box,
  Button,
  Typography
} from "@material-ui/core";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useRef, useState } from "react";

import { analytics_logNavigationClick } from "internal/AnalyticsLib";
import useAuth from "../../hooks/useAuth";
import { useSnackbar } from "notistack";

const RegisterLoginBox = () => {
  const anchorRef = useRef(null);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [UserData, setUserData] = useState(false);

  const logRegister = () => {
    analytics_logNavigationClick("Register")
  };

  const logLogin = () => {
    analytics_logNavigationClick("Login")
  };

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Typography color="textPrimary" variant="subtitle2">
          Want to become a member?
        </Typography>
        <Typography color="textSecondary" variant="body2">
          Premium Features required?
        </Typography>
        <Button
          onClick={logRegister}
          color="primary"
          component={RouterLink}
          fullWidth
          sx={{ mt: 2 }}
          to="/register"
          variant="contained"
        >
          Register
        </Button>
        <Button
          onClick={logLogin}
          color="primary"
          component={RouterLink}
          fullWidth
          sx={{ mt: 2 }}
          to="/login"
          variant="outlined"
        >
          Login
        </Button>
      </Box>
    </>
  );
};

export default RegisterLoginBox;
