import { IconButton, Tooltip } from "@material-ui/core";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import { analytics_logOpening } from "internal/AnalyticsLib";
import { analytics } from "lib/firebase";
import { Link as RouterLink } from "react-router-dom";

const ContactUsPopover = () => {
  const openContact = () => {
    analytics_logOpening("ContactsPopOver");
  };

  return (
    <>
      <Tooltip title="Contact Us">
        <IconButton
          onClick={openContact}
          to="/contact"
          component={RouterLink}
          color="inherit"
        >
          <ContactSupportIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default ContactUsPopover;
