import { analytics, firebase, firestore, storage } from "lib/firebase";
import { createUUID, isInAppBrowser } from "./LayoutFunctions";

import { AppVersion } from "./FixedStrings";
import { DefaultUserData } from "./DefaultData";
import { TableInShareMap } from "internal/Sharing";
import { analytics_logCrash } from "./AnalyticsLib";
import { debugging } from "./NotCheckinStrings";
import { getPublicKey } from "internal/Extraction.js";
import { getUserDocRef } from "./DBLocations";
import { getUserID } from "internal/Checks.js";
import imageCompression from "browser-image-compression";
import { writeAdminMail } from "internal/MailFunctions";

// Use Case specific
export const writeRequest = (requestData, type) => {
  return new Promise(async (resolve, reject) => {
    var collectionName = "requests";

    var data = {
      requestData: requestData,
      user_id: getUserID(),
      answered: false,
      type: type,
      date: new Date(),
    };
    analytics.logEvent("writeRequest_" + type, { data: data });

    writeAdminMail(
      "Neuer Request",
      "Hey, es gibt einen neuen Request\n" + type + JSON.stringify(data, null, 2)
    );

    resolve(writeGlobalDataCollection(collectionName, data));
  });
};

export const isMobile = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const browserData = () => {
  // BrowserDetection
  // Opera 8.0+
  var isOpera =
    !!window.opr || !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;
  // Firefox 1.0+
  var isFirefox = typeof InstallTrigger !== "undefined";
  // Safari 3.0+ "[object HTMLElementConstructor]"
  var isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(!window["safari"]);
  // Internet Explorer 6-11
  var isIE = /*@cc_on!@*/ false || !!document.documentMode;
  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia;
  // Chrome 1 - 71
  var isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  // Blink engine detection
  var isBlink = (isChrome || isOpera) && !!window.CSS;
  return {
    isOpera: isOpera,
    isFirefox: isFirefox,
    isSafari: isSafari,
    isIE: isIE,
    isEdge: isEdge,
    isChrome: isChrome,
    isBlink: isBlink,
  };
};

// Use Case specific
export const writeLog = (requestData, type, writeMail = true) => {
  return new Promise(async (resolve, reject) => {
    var collectionName = "log";

    var data = {
      requestData: requestData,
      user_id: getUserID(),
      answered: false,
      type: type,
      AppVersion: AppVersion,
      date: new Date(),
      deviceData: {
        deviceOS: window.navigator.platform,
        browserData: browserData(),
        isMobile: isMobile(),
        isInAppBrowser: isInAppBrowser(),
      },
    };

    // analytics.logEvent("log_" + type, { data: data });
    if (writeMail && !debugging) {
      writeAdminMail(
        "Neuer Log",
        "Hey, es gibt einen neuen Log\n" + type + JSON.stringify(data, null, 2)
      );
    }
    resolve(writeGlobalDataCollection(collectionName, data));
  });
};

export const getRequests = () => {
  return new Promise((resolve, reject) => {
    var collectionName = "requests";

    resolve(readGlobalDataCollection(collectionName));
  });
};

export const changeRequest = (docName, key, value) => {
  return new Promise((resolve, reject) => {
    var collectionName = "requests";

    resolve(changeGlobalDataCollection(collectionName, docName, key, value));
  });
};

// Generating new doc method, maybe implement a doc method -> access data directly (not via array)
export const writeGlobalDataCollection = (collectionName, data) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("globalData")
      .doc("globalDoc")
      .collection(collectionName)
      .add(data);

    resolve(true);
  });
};

// Generating new doc method, maybe implement a doc method -> access data directly (not via array)
export const changeGlobalDataCollection = (
  collectionName,
  docName,
  key,
  value
) => {
  return new Promise((resolve, reject) => {
    var docRef = firestore
      .collection("globalData")
      .doc("globalDoc")
      .collection(collectionName)
      .doc(docName);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data();
        }
      })
      .then((doc_data) => {
        if (doc_data != null) {
          // console.log(doc_data);
          doc_data[key] = value;
          // console.log(doc_data);

          firestore
            .collection("globalData")
            .doc("globalDoc")
            .collection(collectionName)
            .doc(docName)
            .set(doc_data);
        } else resolve(null);
      });

    resolve(true);
  });
};

// Returns total array of all docs
export const readGlobalDataCollection = (docName) => {
  return new Promise((resolve, reject) => {
    var docRef = firestore
      .collection("globalData")
      .doc("globalDoc")
      .collection(docName);

    var requestArray = [];
    docRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          requestArray.push({ id: doc.id, data: doc.data() });
        });
        resolve(requestArray);
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  });
};

// data is the whole doc
// shouldn't be used -> use transactions instead
export const writeGlobalDoc = async (docName, data) => {
  return new Promise(async (resolve, reject) => {
    var docRef = firestore.collection("globalData").doc(docName);
    var returnValue = await firestore
      .runTransaction((transaction) => {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(docRef).then((doc) => {
          // if (!doc.exists) {
          //   throw "Document does not exist!";
          // }

          var newData = { data: data };
          transaction.set(docRef, newData);
        });
      })
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        console.log("Transaction failed: ", error);
      });
    resolve(returnValue);
  });
};

// Not working
export const readGlobalDoc = (docName) => {
  return new Promise((resolve, reject) => {
    var docRef = firestore.collection("globalData").doc(docName);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data();
        }
      })
      .then((doc_data) => {
        if (doc_data != null) resolve(doc_data);
        else resolve(null);
      });
  });
};

// Basically writing UserData (is equal )
export const writeDBData = (subDocName, data) => {
  return new Promise(async (resolve, reject) => {
    resolve(writeDBDataTransaction(subDocName, data));
  });
};

export const writeDBDataWithUid = (subDocName, data, user_id) => {
  return new Promise(async (resolve, reject) => {
    resolve(writeDBDataWithUidTransaction(subDocName, data, user_id));
  });
};

export const updateDBDataTransaction = (docName, element, value) => {
  return new Promise(async (resolve, reject) => {
    var docRef = getUserDocRef(docName);
    var returnValue = await firestore
      .runTransaction((transaction) => {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(docRef).then((doc) => {
          if (!doc.exists) {
            throw "Document does not exist!";
          }

          var sfData = doc.data();
          var updatedUserData = { data: { ...sfData.data, [element]: value } };

          transaction.update(docRef, updatedUserData);
        });
      })
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        console.log("Transaction failed: ", error);
      });
    resolve(returnValue);
  });
};

export const writeDBDataTransaction = (subDocName, newData) => {
  return new Promise(async (resolve, reject) => {
    var uid = await getUserID();
    resolve(writeDBDataWithUidTransaction(subDocName, newData, uid));
  });
};

export const writeDBDataWithUidTransaction = (subDocName, data, user_id) => {
  return new Promise(async (resolve, reject) => {
    var docRef = firestore.collection("userStorage").doc(user_id);
    var returnValue = await firestore
      .runTransaction((transaction) => {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(docRef).then((doc) => {
          if (!doc.exists) {
            console.log("Document does not exist!");
          }

          docRef.set({ [subDocName]: data }, { merge: true });
          return true;
        });
      })
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        console.log("Transaction failed: ", error);
      });
    resolve(returnValue);
  });
};

// Generating new doc method, maybe implement a doc method -> access data directly (not via array)
// export const changeDBDataWithUid = (
//   collectionName,
//   docName,
//   key,
//   value
// ) => {
//   return new Promise((resolve, reject) => {
//     var docRef = firestore
//       .collection("globalData")
//       .doc("globalDoc")
//       .collection(collectionName)
//       .doc(docName);

//     docRef
//       .get()
//       .then((doc) => {
//         if (doc.exists) {
//           return doc.data();
//         }
//       })
//       .then((doc_data) => {
//         if (doc_data != null) {
//           console.log(doc_data);
//           doc_data[key] = value;
//           console.log(doc_data);

//           firestore
//             .collection("globalData")
//             .doc("globalDoc")
//             .collection(collectionName)
//             .doc(docName)
//             .set(doc_data);
//         } else resolve(null);
//       });

//     resolve(true);
//   });
// };

export const readDBData = (docName, allowPublicKey = false) => {
  return new Promise(async (resolve, reject) => {
    var user_id;
    if (allowPublicKey && getPublicKey() != null) {
      // Get the publicKey as user_id
      // check if permission is provided
      user_id = getPublicKey();

      // Working, but could be improved for clarity
      await readDBDataWithUid("Share", user_id).then((doc_data) => {
        var ShareName = TableInShareMap(docName);

        if (doc_data[ShareName].active) {
          // Permission granted
          user_id = getPublicKey();
        } else {
          // Permission denied
          user_id = null;
          return resolve(null);
        }
      });
    } else {
      user_id = getUserID();
      // Use usual path
      if (user_id == null) {
        return resolve(null);
      }
    }

    resolve(readDBDataWithUid(docName, user_id));
  });
};

export const readDBDataWithUid = (subDocName, user_id) => {
  return new Promise((resolve, reject) => {
    var docRef = firestore.collection("userStorage").doc(user_id);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data();
        }
      })
      .then((doc_data) => {
        if (doc_data != null) resolve(doc_data[subDocName]);
        else resolve(null);
      });
  });
};

// todo: maybe include user?
export const uploadFile = (fileToUpload) => {
  return new Promise(async (resolve, reject) => {
    // todo: enhance with fileinformation
    if (fileToUpload == null) {
      console.log("No file selected - Abort.");
      return false;
    }

    // Compression
    const options = {
      maxSizeMB: 0.3,
      useWebWorker: true,
    };
    const compressedFile = await imageCompression(fileToUpload, options);

    var storageRef = storage.ref();

    return storageRef
      .child("uploadedFiles/" + createUUID() + "_" + fileToUpload.name)
      .put(compressedFile)
      .then((snapshot) => {
        console.log("File uploaded");
        // console.log(snapshot);
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          if (downloadURL != null) resolve(downloadURL);
          else {
            analytics_logCrash("UploadCrashed", {
              fileToUpload: fileToUpload,
              compressedFile: compressedFile,
              snapshot: snapshot,
            });
            resolve(false);
          }
        });
      });
  });
};

// Array operations
export const appendDBArray = (docName, arrayElement) => {
  return new Promise((resolve, reject) => {
    var user_id = getUserID();
    if (user_id == null) return false;

    const docRef = firestore
      .collection("userStorage")
      .doc("users")
      .collection(user_id)
      .doc(docName);

    // todo: if non-existing -create
    docRef
      .update({
        data: firebase.firestore.FieldValue.arrayUnion(arrayElement),
      })
      .then((result) => {
        if (result != null) resolve(true);
        else resolve(false);
      });
  });
};

export const removeDBArray = (docName, arrayElement) => {
  return new Promise((resolve, reject) => {
    var user_id = getUserID();
    if (user_id == null) return false;

    const docRef = firestore
      .collection("userStorage")
      .doc("users")
      .collection(user_id)
      .doc(docName);

    docRef
      .update({
        data: firebase.firestore.FieldValue.arrayRemove(arrayElement),
      })
      .then((result) => {
        if (result != null) resolve(true);
        else resolve(false);
      });
  });
};

export const deleteDoc = (docName) => {
  return new Promise((resolve, reject) => {
    var user_id = getUserID();
    if (user_id == null) return false;

    const docRef = firestore
      .collection("userStorage")
      .doc("users")
      .collection(user_id)
      .doc(docName);

    docRef.delete().then((result) => {
      if (result != null) resolve(true);
      else resolve(false);
    });
  });
};

// Not working

// Array operations
// key as {link: "https://"}
// Array is nested array, not
// Array=[{element:1, link=https}, {element:2,link=https}]
export const substituteDBArrayElement = (docName, arrayElement, key) => {
  return new Promise(async (resolve, reject) => {
    var user_id = getUserID();
    if (user_id == null) return false;

    // Loading the whole document
    var old_doc = await readDBData(docName);

    // Search for key
    // console.log(old_doc);
    if (old_doc != null) {
      // Nothing to update
      var new_doc = old_doc.map((medRecord) => {
        if (medRecord.link == key) {
          medRecord = arrayElement;
        }
      });
    }
    writeDBData(docName, old_doc);
  });
};

// todo:query here
export const getUid = (userName_input) => {
  return new Promise(async (resolve, reject) => {
    // Get all users ids (not possible to iterate otherwise)
    var uid_array;
    const docRef = firestore.collection("userStorage").doc("user_ids");
    await docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("exists");
          // console.log(doc.data());
          return doc.data();
        }
      })
      .then((doc_data) => {
        if (doc_data != null) uid_array = doc_data["data"];
        else uid_array = [];
      });

    // Iterate over all uids and serach for the userName
    // Return the id then
    var userName_it;
    uid_array.forEach(async (uid) => {
      await readDBDataWithUid("UserData", uid).then((doc_data) => {
        // console.log(doc_data);
        if (doc_data != null) {
          userName_it = doc_data.userName;
          if (userName_it == userName_input) {
            resolve(uid);
          }
        }
      });
    });
  });
};

export const getUidFromInstagramName = async (instagramName_input) => {
  var result = await getIDsqueryCollectionFS(
    "userStorage",
    "responseData.username",
    instagramName_input,
    "UserPageData"
  );
  return result[0];
};

// Array operations
export const updateUserIDArray = (uid, active = true) => {
  return new Promise((resolve, reject) => {
    const docRef = firestore.collection("userStorage").doc("user_ids");

    var uid_array;

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data();
        }
      })
      .then((doc_data) => {
        if (doc_data != null) uid_array = doc_data["data"];
        else uid_array = [];

        //todo: not active remove
        docRef
          .update({
            data: firebase.firestore.FieldValue.arrayUnion(uid),
          })
          .then((result) => {
            if (result != null) resolve(true);
            else resolve(false);
          });
      });
  });

  // todo: if non-existing -create
};

// Chat functionality
export const getThreadsFirebase = () => {
  return new Promise((resolve, reject) => {
    var docRef = firestore.collection("chat").doc("threads");

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data();
        }
      })
      .then((doc_data) => {
        if (doc_data != null) resolve(doc_data);
        else resolve(null);
      });
  });
};

export const getContactsFirebase = () => {
  return new Promise((resolve, reject) => {
    var docRef = firestore.collection("chat").doc("contacts");

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data();
        }
      })
      .then((doc_data) => {
        if (doc_data != null) resolve(doc_data);
        else resolve(null);
      });
  });
};

export const addThreadFirebase = (thread) => {
  return new Promise(async (resolve, reject) => {
    var threads = await getThreadsFirebase();

    threads["data"].push(thread);

    await updateThreadsFirebase(threads);
    resolve(true);
  });
};

export const addContactFirebase = (contact) => {
  return new Promise(async (resolve, reject) => {
    var contacts = await getContactsFirebase();

    contacts["data"].push(contact);

    await updateContactsFirebase(contacts);

    resolve(true);
  });
};

export const updateThreadsFirebase = (threads) => {
  return new Promise((resolve, reject) => {
    var docRef = firestore.collection("chat").doc("threads");

    docRef.update(threads).then(() => {
      console.log("Document successfully updated!");
      resolve(true);
    });
  });
};

export const updateContactsFirebase = (contacts) => {
  return new Promise((resolve, reject) => {
    var docRef = firestore.collection("chat").doc("contacts");

    docRef.update(contacts).then(() => {
      console.log("Document successfully updated!");
      resolve(true);
    });
  });
};

// New functions
export const getUserDataFS = async (
  callbackFunc = undefined,
  uid = getUserID()
) => {
  // return getFSData("userStorage", uid, "UserData", callbackFunc, DefaultUserData);
  return getUserStorageSubDocFS("UserData", callbackFunc, DefaultUserData, uid);
};

export const getUserStorageSubDocFS = async (
  subDocName = undefined,
  callbackFunc = undefined,
  defaultData = undefined,
  uid = getUserID()
) => {
  return getDataFS("userStorage", uid, subDocName, callbackFunc, defaultData);
};

// If callbackFunc given -> return null, only on callbackFunc will be written
export const getDataFS = async (
  collectionName,
  docName,
  subDocName = undefined,
  callbackFunc = undefined,
  defaultData = undefined
) => {
  var docData;

  if (callbackFunc) {
    attachListenerToDataFS(
      collectionName,
      docName,
      callbackFunc,
      subDocName,
      defaultData
    );
    docData = null;
  } else {
    if (collectionName && docName) {
      var docRef = firestore.collection(collectionName).doc(docName);

      var doc = await docRef.get();
      if (doc.exists) {
        docData = doc.data();
        if (subDocName != null) {
          docData = docData[subDocName];
        }
      }
    }

    if (docData == null) {
      docData = defaultData;
    }
  }

  return docData;
};

export const attachListenerToDataFS = async (
  collectionName,
  docName,
  callbackFunc,
  subDoc = undefined,
  defaultData = undefined
) => {
  var callbackData;

  if (collectionName && docName) {
    var docRef = firestore.collection(collectionName).doc(docName);

    await docRef.onSnapshot(async (doc) => {
      var docData = doc.data();

      if (subDoc && docData) {
        callbackData = docData[subDoc];
      } else {
        callbackData = docData;
      }

      if (callbackData == null && defaultData != undefined) {
        callbackData = defaultData;
      }

      callbackFunc(callbackData);
    });
  }
};

// setter
export const setUserDataFS = async (data, uid = getUserID()) => {
  return setUserStorageSubDocFS(data, "UserData", uid);
};

export const setUserStorageSubDocFS = async (
  data,
  subDocName,
  uid = getUserID()
) => {
  return setDataFS(data, "userStorage", uid, subDocName);
};

export const setDataFS = async (
  data,
  collectionName,
  docName,
  subDocName = undefined,
  writeOnly = false
) => {
  var docRef = firestore.collection(collectionName).doc(docName);

  if (writeOnly) {
    // Direkter Schreibvorgang ohne Transaktion
    if (subDocName) {
      await docRef.set({ [subDocName]: data }, { merge: true });
    } else {
      await docRef.set(data);
    }
    return true;
  }

  // Transaktion wie bisher
  var returnValue = await firestore
    .runTransaction((transaction) => {
      return transaction.get(docRef).then((doc) => {
        // Optional: Falls Dokument nicht existiert, kann hier eine Fehlermeldung geworfen werden
        // if (!doc.exists) {
        //   throw "Document does not exist!";
        // }

        // Substitute through transaction
        if (subDocName) {
          transaction.set(docRef, { [subDocName]: data }, { merge: true });
        } else {
          transaction.set(docRef, data);
        }
      });
    })
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log("Transaction failed: ", error);
    });

  return returnValue;
};

export const getIDsqueryCollectionFS = async (
  collectionName,
  key,
  value,
  subDocName = undefined
) => {
  var docRef = firestore
    .collectionGroup(collectionName)
    .where(subDocName + "." + key, "==", value);

  var querySnapshot = await docRef.get();

  var resultArray = [];
  querySnapshot.forEach((doc) => {
    resultArray.push(doc.id);
  });

  return resultArray;
};

export const getDataqueryCollectionFS = async (
  collectionName,
  key,
  value,
  subDocName = undefined,
  operator = undefined
) => {
  var docRef = firestore
    .collectionGroup(collectionName)
    .where(
      subDocName ? subDocName + "." + key : key,
      operator ? operator : "==",
      value
    );

  var querySnapshot = await docRef.get();

  var resultArray = [];
  querySnapshot.forEach((doc) => {
    resultArray.push(doc.data());
  });

  return resultArray;
};
