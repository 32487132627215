import { debugging } from "./NotCheckinStrings";

export const websiteURL = debugging
  ? "http://localhost:3000"
  : "https://creatordash.net";
export const apiURL = debugging
  ? "http://localhost:5001/creatordash-a90d0/us-central1"
  : "https://us-central1-creatordash-a90d0.cloudfunctions.net";

export const AppVersion = "1.5.0";
