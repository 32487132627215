import { AppVersion, apiURL } from "./FixedStrings";
import { browserData, isMobile, writeLog } from "internal/DBFunctions";

import { analytics } from "lib/firebase";
import { debugging } from "./NotCheckinStrings";
import { getUserID } from "internal/Checks.js";
import { isInAppBrowser } from "./LayoutFunctions";

const now = new Date();

export const analytics_logPageView = (title, logData = {}) => {
  title = "PageView_" + title;
  logData = extendedLogData(logData);
  writeLog(logData, title, false);
  logEvent(title, logData);
};

export const analytics_logButtonClick = (title, logData = {}) => {
  title = "ButtonClick_" + title;
  logData = extendedLogData(logData);
  writeLog(logData, title, false);
  logEvent(title, logData);
};

export const analytics_logNavigationClick = (title, logData = {}) => {
  title = "Navigation_" + title;
  logData = extendedLogData(logData);
  writeLog(logData, title, false);
  logEvent(title, logData);
};

export const analytics_logEvent = (title, logData = {}) => {
  title = "Event_" + title;
  logData = extendedLogData(logData);
  writeLog(logData, title, false);
  logEvent(title, logData);
};

export const analytics_logOpening = (title, logData = {}) => {
  title = "Opening_" + title;
  logData = extendedLogData(logData);
  writeLog(logData, title, false);
  logEvent(title, logData);
};

export const analytics_logClosing = (title, logData = {}) => {
  title = "Closing_" + title;
  logData = extendedLogData(logData);
  writeLog(logData, title, false);
  logEvent(title, logData);
};

export const analytics_logError = (title, logData = {}) => {
  title = "Error_" + title;
  logData = extendedLogData(logData);
  writeLog(logData, title, true);
  logEvent(title, logData);
};

export const analytics_logSuccess = (title, logData = {}) => {
  title = "Success_" + title;
  logData = extendedLogData(logData);
  writeLog(logData, title, false);
  logEvent(title, logData);
};

export const analytics_logAborting = (title, logData = {}) => {
  title = "Aborting_" + title;
  logData = extendedLogData(logData);
  writeLog(logData, title, false);
  logEvent(title, logData);
};

export const analytics_logUseFunctionality = (title, logData = {}) => {
  title = "UseFunctionality_" + title;
  logData = extendedLogData(logData);
  writeLog(logData, title, false);
  logEvent(title, logData);
};

export const analytics_logCrash = (title, logData = {}) => {
  title = "Crash_" + title;
  logData = extendedLogData(logData);
  writeLog(logData, title,false);
  logEvent(title, logData);
};

// A conversion that is defined as more than just using the website (application/deal submission)
export const analytics_logUsageConversion = (title, logData = {}) => {
  title = "UsageConversion_" + title;
  logData = extendedLogData(logData);
  writeLog(logData, title);
  logEvent(title, logData);
};

export const analytics_logMikroConversion = (title, logData = {}) => {
  title = "MicroConversion_" + title;
  logData = extendedLogData(logData);
  writeLog(logData, title);
  logEvent(title, logData);
};

export const analytics_logMacroConversion = (title, logData = {}) => {
  title = "MacroConversion_" + title;
  logData = extendedLogData(logData);
  writeLog(logData, title);
  logEvent(title, logData);
};

export const analytics_setUserProperties = async (userProperties) => {
  const deviceData = {
    deviceOS: window.navigator.platform,
    browserData: await browserData(),
    isMobile: await isMobile(),
    isInAppBrowser: await isInAppBrowser(),
  };
  userProperties = Object.assign({}, userProperties, deviceData);
  analytics.setUserProperties(userProperties);
};

// Common functions
export const extendedLogData = (logData) => {
  return {
    logData: logData,
    user_id: getUserID(),
    AppVersion: AppVersion,
    referrer: window.document.referrer,
    date: new Date(),
    deviceData: {
      deviceOS: window.navigator.platform,
      browserData: browserData(),
      isMobile: isMobile(),
    },
    window: window.location.href,
  };
};

export const logEvent = (title, logData) => {
  if (!debugging)
    analytics.logEvent(title, { logData: JSON.stringify(logData) });
};
