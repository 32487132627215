import { experimentalStyled } from "@material-ui/core/styles";
import logo_round from "assets/creatordash_insta_logo_round.PNG";

const LogoRoot = experimentalStyled("div")();

const Logo = (props) => (
  <LogoRoot {...props}>
    <img {...props}  alt="CreatorDash" src={logo_round} />
  </LogoRoot>
);

export default Logo;
