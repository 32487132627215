export const OneClickApplication = false;
export const Collaborations = false;
export const MyPage = false;

// Is working, but not nice. Hopefully other spam detection measures are better.
// Users should not be able to trigger spam or something like that.
// Everybody should be able to
// Auch ärgern von Leute mit deren Mail sollte verhindert werden.
// Also keep in mind that only email users receive this email
// Currently 2 problems: 1) Ugly template, 2)
export const EMailVerification = true;

// Mit dem Feature werden keine Mails mehr gesendet, nur noch Notifications und eine tägliche Mail (einmalig, ob neue Notifications vorhanden sind)
export const NotificationsOnly = false;

export const serviceWorkerEnabled = false;

export const addingFakeReviews = false;
