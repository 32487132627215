import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


// Is actually not working 
const useScrollReset = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

export default useScrollReset;
