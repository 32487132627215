import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";

import AccountPopover from "./AccountPopover";
import ContactUsPopover from "./ContactUsPopover";
import Logo_Round from "components/Logo_Round";
import MenuIcon from "../../icons/Menu";
import NotificationsPopover from "./NotificationsPopover";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { experimentalStyled } from "@material-ui/core/styles";

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarMobileOpen}>
            <MenuIcon fontSize="small" />
          </IconButton>
        </Hidden>
        <Hidden lgDown>
          <RouterLink to="/">
          <Logo_Round height="40" width="40" />

          </RouterLink>
        </Hidden>
        <Hidden smDown>
          <Box
            sx={{
              textDecoration: "none",
              color: "white",
            }}
            component={RouterLink}
            to="/"
            variant="text"
            display="flex"
            justifyContent="center"
            m={1}
            p={1}
          >
            <Typography variant="h5">CreatorDash</Typography>
          </Box>
        </Hidden>

        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        {/* <Box sx={{ ml: 1 }}>
          <ContactUsPopover />
        </Box> */}
        {/* <Box sx={{ ml: 1 }}>
          <ContentSearch />
        </Box> */}
        <Box sx={{ ml: 1 }}>
          <NotificationsPopover />
        </Box>
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
