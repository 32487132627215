/* eslint-disable react/prop-types */

import React, { Component } from "react";
import Header from "./Header";
import FeedbackFooter from "./FeedbackFooter";
import Body from "./Body";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { writeLog } from "internal/DBFunctions";

const formStyles = {
  position: "fixed",
  backgroundColor: "#FAFAFA",
  border: "1px solid #dcdcdc",
  borderRadius: "6px 6px 0 0",
  zIndex: "50000",
  bottom: "0px",
};

const Form = (props) => {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [message, setmessage] = useState("");

  const handleSend = (ev) => {
    ev.preventDefault();

    var logData = {
      windowLocation: window.location.href,
      name: name,
      email: email,
      message: message,
    };
    writeLog(logData, "New_Feedback");

    handleSubmit();
    return true;
  };

  const {
    headerStyles,
    headerText,
    position,
    handleClose,
    handleSubmit,
    handleMessageInput,
    handleEmailInput,
    handleRatingInput,
    handleCustomPosition,
    messageInput,
    emailInput,
    ratingInput,
    showEmailInput,
    showMessageInput,
    showRatingInput,
    numberOfStars,
    bodyText,
    nameInput,
  } = props;

  var customFormStyles = handleCustomPosition(position, formStyles);
  return (
    <div style={{ ...customFormStyles, ...props.style }}>
      <Header
        styles={headerStyles}
        headerText={headerText}
        handleClose={handleClose}
      />
    
      <Box
        sx={{
          backgroundColor: "background.paper",
          minHeight: "100%",
          pr: 3,
          pl: 3,
          pt: 3,
        }}
      >
        <form onSubmit={handleSend}>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                onChange={(ev) => setname(ev.target.value)}
                value={name}
                fullWidth
                label="Name"
                name="Name"
                variant="outlined"
                size="small"

/>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                onChange={(ev) => setemail(ev.target.value)}
                value={email}
                fullWidth
                label="Email"
                
                name="Email"
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                onChange={(ev) => setmessage(ev.target.value)}
                value={message}
                rows={4}
                fullWidth
                size="small"
                multiline
                label="Write your message"
                name="Message"
                variant="outlined"
                required
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              p: 2,
            }}
          >
            <Button fullWidth color="primary" type="submit" variant="contained">
              Send
            </Button>
          </Box>
        </form>
      </Box>

      {/* <Body
          bodyText={bodyText}
          numberOfStars={numberOfStars}
          showEmailInput={showEmailInput}
          showMessageInput={showMessageInput}
          showRatingInput={showRatingInput}
          handleMessageInput={handleMessageInput}
          handleEmailInput={handleEmailInput}
          handleRatingInput={handleRatingInput}
          messageInput={messageInput}
          emailInput={emailInput}
          ratingInput={ratingInput}
        /> */}
      {/* <Footer
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          /> */}
    </div>
  );
};

export default Form;
