export function getRandomNumberFloor(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

export function getRandomNumber(min, max) {
  let randomNumber = Math.random() * (max - min) + min;
  return Math.round(randomNumber * 10) / 10;
}

export function getRandomNumberFloorMax(max) {
  return Math.floor(Math.random() * max);
}
