import { experimentalStyled } from '@material-ui/core/styles';
import { Outlet } from 'react-router-dom';

const BlogLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100%',
}));

const BlogLayout = () => (
  // <BlogLayoutRoot>
    <Outlet />
  // </BlogLayoutRoot>
);

export default BlogLayout;
