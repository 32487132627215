import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

import { debugging } from "internal/NotCheckinStrings";
import firebase from 'firebase/app';
import { firebaseConfig } from "../config";

// import ReactGA from 'react-ga';


firebase.initializeApp(firebaseConfig);

// if (!firebase.apps.length) {
//   firebase.initializeApp(firebaseConfig);
// }

// alias
const firestore = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const analytics = firebase.analytics();

if (location.hostname === "localhost" && debugging) {
  // add other emulators like auth etc.
  firestore.settings({ host: "localhost:8080", ssl: false });
  auth.useEmulator("http://localhost:9099");
}

// // ReactGA.initialize('UA-000000-01');
// ReactGA.initialize('UA-000000-01', {
//   debug: true,
//   titleCase: false,
//   // gaOptions: {
//   //   userId: 123
//   // }
// });

// ReactGA.pageview(window.location.pathname + window.location.search);

// todo: Maybe add this to access offline pwa
// https://stackoverflow.com/questions/57234932/how-to-use-firebase-auth-and-cloud-firestore-from-different-components-as-a-sing
// https://firebase.google.com/docs/projects/pwa
if (!debugging) {
  firestore.enablePersistence().catch((err) => {
    if (err.code == "failed-precondition") {
      console.log(err);
      // analytics_logError("EnableCacheFailedPrecondition", err)
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code == "unimplemented") {
      console.log(err);
      // analytics_logError("EnableCacheUnimplemented", err)

      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });
}

export { firestore, auth, firebase, storage, analytics };

