import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";


const GenericHelmet = (props) => {
  const { title, description, imageURL, subTitle, ...other } = props;

  const Title = () => {
    if (title == "" || title == null) {
      return "Best place to find Brand Deals for Content Creators";
    } else {
      return title;
    }
  };

  const SubTitle = () => {
    if (subTitle == "" || subTitle == null) {
      return "CreatorDash";
    } else {
      return subTitle;
    }
  };

  const Description = () => {
    if (description == "" || description == null) {
      return "Brand Deals, Influencer Dashboard and Paid Follower Interactions. Brand Deals with small companies and with big corporations.";
    } else {
      return description;
    }
  };

  const ImageURL = () => {
    if (imageURL == "" || imageURL == null) {
      return "https://creatordash.net/logo1200_600_influencerGroup.jpg";
    } else {
      if (imageURL.includes("/static")) {
        return "https://creatordash.net" + imageURL;
      } else {
        return imageURL;
      }
    }
  };

  const CompleteTitle = () => {
    return Title() + " | " + SubTitle();
  };

  const CurrentURL = () => {
    return "https://creatordash.net" + location.pathname;
  };

  return (
    <Helmet>
      {/* <!-- Meta Tags Generated via http://heymeta.com -->
    <!-- HTML Meta Tags --> */}
      <title>{CompleteTitle()}</title>
      <meta name="description" content={Description()} />
      <meta property="og:image" content={ImageURL()} />

      {/* <!-- Google / Search Engine Tags --> */}
      <meta itemProp="name" content={CompleteTitle()} />
      <meta itemProp="description" content={Description()} />
      <meta itemProp="image" content={ImageURL()} />

      {/* <!-- Facebook Meta Tags --> */}
      <meta property="og:url" content={CurrentURL()} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={CompleteTitle()} />
      <meta property="og:description" content={Description()} />
      <meta property="og:image" content={ImageURL()} />

      {/* <!-- Twitter Meta Tags --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={CompleteTitle()} />
      <meta name="twitter:description" content={Description()} />
      <meta name="twitter:image" content={ImageURL()} />
    </Helmet>
  );
};

GenericHelmet.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  imageURL: PropTypes.string,
};

export default GenericHelmet;
