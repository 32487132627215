import { readDBData, writeDBData } from "internal/DBFunctions";

import { getUserDocRef } from "./DBLocations";
import { getUserID } from "internal/Checks.js";
import { isSharingAllowed } from "internal/Sharing.js";
import { readDBDataWithUid } from "./DBFunctions";

// import LoginAlert from "components/LoginAlert/LoginAlert.js";
// import PopUp from "components/PopUp/PopUp.js";

// export const openLoginAlert = () => {
//   console.log("openLoginAlert");

//   const loginState = {
//     openLoginRequired: true,
//   };
//   return <LoginAlert loginState={loginState} />;
// };

// export const openPopUp = () => {
//   console.log("openPopUp");

//   const PopUpProps = {
//     openPopUp: true,
//     message: "File Successfully uploaded",
//   };
//   return <PopUp popUp={PopUpProps} />;
// };

// export const openWindow = (link) => {
//   window.open(link);
// };

// Return 17.12.2020
export const getCurrentDate = (separator = ".") => {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${date}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${year}`;
};

// Return 17.12.2020
export const getStringDate = (inputDate) => {
  var separator = ".";
  let date = inputDate.getDate();
  let month = inputDate.getMonth() + 1;
  let year = inputDate.getFullYear();

  return `${date}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${year}`;
};

// Fetch the table from Firebase (Original data)
// Is called when table is changed
export const TableFetchOnce = async(
  TableName,
  dispatchFunction,
  defaultData = null,
) => {
  // console.log(writeinData);
  return new Promise(async(resolve, reject) => {
    readDBData(TableName, false).then(async(doc_data) => {
      var data_to_write;
      if (doc_data != null) {
        data_to_write = doc_data;
      }
      // Reading wasn't successful, check if defaultData is provided
      else {
        data_to_write = defaultData;
      }

        // Overwriting
        await dispatchFunction(data_to_write);
        resolve(doc_data != null);
    });
  });
};

// Fetch the table from Firebase (Original data)
// Is called when table is changed
export const TableFetch = async (TableName, dispatchFunction, defaultData = null) => {
  // console.log(writeinData);
  return new Promise((resolve, reject) => {
    if (getUserDocRef(TableName) == false) {
      resolve(defaultData);
    }
    getUserDocRef(TableName).onSnapshot(async(doc) => {
      var doc_data = doc.data();

      var data_to_write;
      if (doc_data != null) {
        data_to_write = doc_data.data;
      }
      // Reading wasn't successful, check if defaultData is provided
      else {
        if (defaultData == null) {
          // Assumption: Default data is not supposed to be null
          resolve(false);
          return;
        } else {
          data_to_write = defaultData;
        }
      }

      // Overwriting
      await dispatchFunction(data_to_write);
      resolve(doc_data != null);
    });
  });
};

export const TableFetchWithUserID = (
  TableName,
  dispatchFunction,
  writeinData = false,
  defaultData = null,
  user_id = getUserID()
) => {
  // console.log(writeinData);
  return new Promise((resolve, reject) => {
    readDBDataWithUid(TableName, user_id).then((doc_data) => {
      var data_to_write;
      if (doc_data != null) {
        data_to_write = doc_data;
      }
      // Reading wasn't successfull, check if defaultData is provided
      else {
        if (defaultData == null) {
          // Assumption: Default data is not supposed to be null
          resolve(false);
          return;
        } else {
          data_to_write = defaultData;
        }
      }

      if (writeinData) {
        dispatchFunction({
          data: data_to_write,
        });
      } else {
        // Overwriting
        dispatchFunction(data_to_write);
      }
      resolve(doc_data != null);
    });
  });
};

// Is called when table is changed
export const TableChanged = (TableName, data) => {
  return new Promise(async (resolve, reject) => {
    resolve(writeDBData(TableName, data));
  });
};
