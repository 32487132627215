import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography
} from "@material-ui/core";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { analytics_logEvent, analytics_logOpening } from "internal/AnalyticsLib";
import { useEffect, useRef, useState } from "react";

import CogIcon from "../../icons/Cog";
import RegisterLoginBox from "./RegisterLoginBox";
import { afterLogoutActions } from "internal/LoginFunctions";
import { getUserDataFS } from "internal/DBFunctions";
import useAuth from "../../hooks/useAuth";
import { useSnackbar } from "notistack";

const AccountPopover = () => {
  const auth = useAuth();

  const anchorRef = useRef(null);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [UserData, setUserData] = useState(false);

  useEffect(() => {
    getUserDataFS(setUserData);
  }, [auth, location.pathname]);

  const handleOpen = () => {
    analytics_logOpening("AccountPopOver");

    setOpen(true);
  };

  const handleClose = () => {
    analytics_logOpening("AccountPopOver");

    setOpen(false);
  };

  const handleLogout = async () => {
    analytics_logEvent("TryingToLogout");

    try {
      handleClose();
      if (user != null) {
        await logout().then(() => {
          afterLogoutActions();
        });
      }
      enqueueSnackbar("User logged out", {
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
        variant: "success",
        autoHideDuration: 3000,
      });
      // navigate("/");
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Unable to logout", {
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        <Avatar
          src={auth.isAuthenticated ? UserData.photoURL : null}
          sx={{
            height: 32,
            width: 32,
          }}
        />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        getContentAnchorEl={null}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        {auth.isAuthenticated ? (
          <>
            <Box sx={{ p: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {UserData ? UserData.name : "Demo User"}
              </Typography>
              <Typography color="textSecondary" variant="subtitle2">
                {UserData ? UserData.membership : "Basic"} user
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ mt: 2 }}>
              {/* <MenuItem onClick={handleClose} component={RouterLink} to="/social/profile">
            <ListItemIcon>
              <UserIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Profile
                </Typography>
              }
            />
          </MenuItem> */}
              <MenuItem
                onClick={handleClose}
                component={RouterLink}
                to="/account"
              >
                <ListItemIcon>
                  <CogIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography color="textPrimary" variant="subtitle2">
                      Settings
                    </Typography>
                  }
                />
              </MenuItem>
            </Box>
            <Box sx={{ p: 2 }}>
              <Button
                color="primary"
                fullWidth
                onClick={handleLogout}
                variant="outlined"
              >
                Logout
              </Button>
            </Box>
          </>
        ) : (
          <RegisterLoginBox />
        )}
      </Popover>
    </>
  );
};

export default AccountPopover;
