import {
  Box,
  Button,
  Container,
  Grid,
  Hidden,
  Skeleton,
  TextField,
  Typography
} from "@material-ui/core";
import { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { analytics_logUseFunctionality } from "internal/AnalyticsLib";
import { subscribeUserToGeneral } from "internal/UserActionFunctions";
import { useSnackbar } from "notistack";
import { useTheme } from "@material-ui/core/styles";

const BlogNewsletter = (props) => {
  const { onSubscribe, closeLayout } = props;

  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState("");
  const [mailAddress, setmailAddress] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  let LayoutShort = {
    backgroundColor: "background.default",
    py: 0.5,
    mt: 0.5,
  };

  let LayoutLarge = {
    backgroundColor: "background.default",
    py: 6,
    mt: 4,
  };

  useEffect(() => {
    (async () => {
      const response = await fetch(
        `/static/blog/blog_${theme.palette.mode}.svg`
      );
      const blob = await response.blob();

      setImage(URL.createObjectURL(blob));
      setIsLoading(false);
    })();
  }, [theme.palette.mode]);

  const handleSubscribeClick = () => {
    subscribeUserToGeneral(mailAddress);

    enqueueSnackbar("Subscribed newsletter", {
      anchorOrigin: {
        horizontal: "center",
        vertical: "top",
      },
      variant: "success",
      autoHideDuration: 3000,
    });
    analytics_logUseFunctionality( "BlogNewsletterUsed",{ mail: mailAddress });

    onSubscribe();
  };

  return (
    <Box sx={closeLayout ? LayoutShort : LayoutLarge}>
      <Container maxWidth="lg">
        <Grid
          alignItems="center"
          container
          justifyContent="space-between"
          spacing={3}
          wrap="nowrap"
        >
          <Grid item md={7} xs={12}>
            <Typography
              color="textSecondary"
              variant="h6"
              align="center"
              sx={{
                mt: 2,
                mb: 1,
              }}
            >
              Instagram, TikTok, YouTube, Twitch
            </Typography>
            <Box
              sx={{
                alignItems: "flex-start",
                display: "flex",
                flexDirection: "column",
                maxWidth: 600,
              }}
            >
              <Typography color="textPrimary" variant="h3">
                New Brand Deals & Features
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                sx={{
                  mb: 4.5,
                  mt: 2,
                }}
              >
                100+ Brands and 100+ Influencers are already supporting each
                other on CreatorDash, big and small.
                <br />
                Get notified when new brand deals and features are added.
              </Typography>
              <TextField
                fullWidth
                label="Email address"
                name="email"
                sx={{ maxWidth: 420 }}
                type="email"
                variant="outlined"
                onChange={() => setmailAddress(event.target.value)}
                value={mailAddress}
              />
              <Button
                color="primary"
                size="large"
                variant="contained"
                sx={{ mt: 2 }}
                onClick={handleSubscribeClick}
              >
                Notify Me
              </Button>
            </Box>
          </Grid>
          <Hidden mdDown>
            <Grid item md={5}>
              <Box
                sx={{
                  maxWidth: 472,
                  width: "100%",
                }}
              >
                {isLoading ? (
                  <Skeleton
                    sx={{
                      borderRadius: 1,
                      pt: "89.19%",
                      width: "100%",
                    }}
                    variant="rectangular"
                  />
                ) : (
                  <img
                    alt="Blog Hero"
                    src={image}
                    style={{ maxWidth: "100%" }}
                  />
                )}
              </Box>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </Box>
  );
};

BlogNewsletter.propTypes = {
  onSubscribe: PropTypes.func,
  closeLayout: PropTypes.bool,
};

export default BlogNewsletter;
