import { AppVersion, apiURL } from "internal/FixedStrings";
import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";

import Logo from "./Logo";
import MinusIcon from "../icons/Minus";
import { Link as RouterLink } from "react-router-dom";
import { alpha } from "@material-ui/core/styles";
import logo_round from "assets/creatordash_insta_logo_round.PNG";

// Alex: Something is very wrong with this list /listitem or formatting
// It doesn't scroll up sometimes. Maybe it is with blank spaces, maybe with map in map. Doesn't work
const sections = [
  {
    title: "Reach out",
    links: [
      {
        title: "FAQ",
        href: "/faq",
      },
      {
        title: "Community",
        href: "/feed",
      },
      {
        title: "Contact",
        href: "/contact",
      },
    ],
  },
  {
    title: "Legal",
    links: [
      {
        title: "Terms & Conditions",
        href: "/terms",
      },
      {
        title: "Privacy",
        href: "/privacy",
      },
    ],
  },
  {
    title: "Social",
    links: [
      {
        title: "Instagram",
        href: "https://instagram.com/creatordashapp",
      },
    ],
  },
];

const Footer = (props) => (
  <Box
    sx={{
      backgroundColor: "background.default",
      pb: 3,
      pt: {
        md: 4,
        xs: 2,
      },
    }}
    {...props}
  >
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid
          item
          md={3}
          sm={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            order: {
              md: 1,
              xs: 4,
            },
          }}
          xs={12}
        >
          {/* <Logo /> */}
          <Box
            sx={{
              height: 64,
              "& > img": {
                maxHeight: "100%",
                width: "auto",
              },
            }}
          >
            <img alt="CreatorDash Platform" src={logo_round} />
          </Box>
          <Box sx={{ mt: 1 }}>
            <Typography color="textSecondary" variant="caption">
              CreatorDash
              <br />
              support@creatordash.net
              <br />
              {AppVersion}
            </Typography>
          </Box>
        </Grid>
        {sections.map((section, index) => (
          <Grid
            item
            key={section.title}
            md={3}
            sm={4}
            sx={{
              order: {
                md: index + 2,
                xs: index + 1,
              },
            }}
            xs={12}
          >
            <Typography color="textSecondary" variant="overline">
              {section.title}
            </Typography>
            <List disablePadding>
              {section.links.map((link) => (
                <ListItem
                  disableGutters
                  key={link.title}
                  sx={{
                    pb: 0,
                    pt: 1,
                  }}
                >
                  <ListItemAvatar
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      minWidth: 0,
                      mr: 0.5,
                    }}
                  >
                    <MinusIcon color="primary" />
                  </ListItemAvatar>

                  {link.href.includes("https") ? (
                    <ListItemText
                      primary={
                        <Link
                          href={link.href}
                          color="textPrimary"
                          variant="subtitle2"
                        >
                          {link.title}
                        </Link>
                      }
                    />
                  ) : (
                    <ListItemText
                      primary={
                        <Link
                          component={RouterLink}
                          to={link.href}
                          color="textPrimary"
                          variant="subtitle2"
                        >
                          {link.title}
                        </Link>
                      }
                    />
                  )}
                </ListItem>
              ))}
            </List>
          </Grid>
        ))}
      </Grid>
      <Divider
        sx={{
          borderColor: (theme) =>
            alpha(theme.palette.primary.contrastText, 0.12),
          my: 3,
        }}
      />
      <Typography color="textSecondary" variant="caption">
        All Rights Reserved.
      </Typography>
    </Container>
  </Box>
);

export default Footer;
