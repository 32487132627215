import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  Typography,
} from "@material-ui/core";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { getUserDataFS, readGlobalDoc } from "internal/DBFunctions";
import { useEffect, useState } from "react";

import AssignmentIcon from "@material-ui/icons/Assignment";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import Logo_Round from "components/Logo_Round";
import NavSection from "../NavSection";
import PropTypes from "prop-types";
import RegisterLoginBox from "./RegisterLoginBox";
import Scrollbar from "../Scrollbar";
import UserIcon from "../../icons/User";
import { UserTypes } from "internal/Structs";
import { getDataqueryCollectionFS } from "internal/DBFunctions";
import useAuth from "../../hooks/useAuth";

const sections = (UserHasDeals, UserHasApplications, UserType) => {
  return [
    {
      title: "General",
      items: [
        // {
        //   title: "Overview",
        //   path: "/",
        //   icon: <ChartSquareBarIcon fontSize="small" />,
        // },

        {
          title: "Brand Deals",
          path: "/deals",
          icon: <span>🔥</span>,
        },

        // {
        //   title: "Collaborations",
        //   path: "/collaborations",
        //   icon: <GroupIcon fontSize="small" />,
        // },
        {
          title: "Blog",
          path: "/blog",

          icon: (
            <span fontSize="small" width="20px" height="20px">
              📚
            </span>
          ),
        },
      ],
    },
    {
      title: "Management",
      items: [
        {
          title: "Account",
          path: "/account",
          icon: <UserIcon fontSize="small" />,
        },
        // {
        //   title: "Analytics",
        //   path: "/analytics",
        //   icon: <ChartPieIcon fontSize="small" />,
        // },
        // {
        //   title: "Finance",
        //   path: "/finance",
        //   icon: <AttachMoneyIcon fontSize="small" />,
        // },
        // {
        //   title: "Invoices",
        //   path: "/invoices",
        //   icon: <ReceiptIcon fontSize="small" />,
        //   // children: [
        //   //   {
        //   //     title: "List",
        //   //     path: "/invoices",
        //   //   },
        //   //   // {
        //   //   //   title: "Example Invoice",
        //   //   //   path: "/invoices/1",
        //   //   // },
        //   // ],
        // },
        // Conditional rendering if user has deals
        UserType == UserTypes.Influencer ||
        UserType == null ||
        UserType == "Unknown"
          ? {
              title: "My Page",
              path: "/pagecreator",
              icon: <ContactPageIcon fontSize="small" />,
            }
          : {},
        UserType == UserTypes.Brand || UserType == null || UserType == "Unknown"
          ? {
              title: "My Deals",
              path: "/mydeals",
              icon: <BusinessCenterIcon fontSize="small" />,
            }
          : {},
        UserType == UserTypes.Influencer ||
        UserType == null ||
        UserType == "Unknown"
          ? {
              title: "My Applications",
              path: "/myapplications",
              icon: <AssignmentIcon fontSize="small" />,
            }
          : {},
        // UserHasApplications
        //   ? {
        //       title: "My Applications",
        //       path: "/myapplications",
        //       icon: <AssignmentIcon fontSize="small" />,
        //     }
        //   : {},
      ],
    },
    // {
    //   title: "Follower Interactions",
    //   items: [
    //     {
    //       title: "Messages",
    //       path: "/messages",
    //       icon: <MessageIcon fontSize="small" />,
    //       children: [
    //         {
    //           title: "Settings",
    //           path: "/messages/preview",
    //         },
    //       ],
    //     },

    //     // TODO: AI Message and Twitter Connection from Main Dashboard
    //     // {
    //     //   title: "Calls",
    //     //   path: "/calls",
    //     //   icon: <CallIcon fontSize="small" />,
    //     // },
    //   ],
    // },

    {
      title: "Community",
      items: [
        // {
        //   title: "Feed",
        //   path: "/feed",
        //   icon: <span>💬</span>,
        // },

        {
          title: "FAQ",
          path: "/faq",
          icon: <span>🙋‍♂️</span>,
        },
      ],
    },

    // {
    //   title: "Apps",
    //   items: [
    //     {
    //       title: "Kanban",
    //       path: "/kanban",
    //       icon: <ClipboardListIcon fontSize="small" />,
    //     },
    //     {
    //       title: "Chat",
    //       path: "/chat",
    //       icon: <ChatAltIcon fontSize="small" />,
    //     },
    //     {
    //       title: "Calendar",
    //       path: "/calendar",
    //       icon: <CalendarIcon fontSize="small" />,
    //     },
    //   ],
    // },
  ];
};

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const auth = useAuth();

  const [UserData, setUserData] = useState([]);
  const [UserHasDeals, setUserHasDeals] = useState(false);
  const [UserHasApplications, setUserHasApplications] = useState(false);

  useEffect(async () => {
    getUserDataFS(setUserData);

    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname, auth]);

  useEffect(async () => {
    if (UserData.uid != null) {
      // Get deals, search dealsId in it
      // Fetch from server
      var allProjects = await getDataqueryCollectionFS(
        "deals",
        "id",
        0,
        undefined,
        "!="
      );

      var MyDealsArray = [];
      allProjects.forEach((deal) => {
        if (deal.uid == UserData.uid) {
          MyDealsArray.push(deal);
        }
      });

      setUserHasDeals(MyDealsArray.length > 0);

      // Application
      var allApplications = await getDataqueryCollectionFS(
        "applications",
        "id",
        0,
        undefined,
        "!="
      );

      var MyApplicationArray = [];
      allApplications.forEach((application) => {
        if (application.uid == UserData.uid) {
          MyApplicationArray.push(application);
        }
      });

      setUserHasApplications(MyApplicationArray.length > 0);
    }
  }, [UserData]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              p: 2,
            }}
          >
            <RouterLink to="/">
              <Logo_Round height="40" width="40" />
            </RouterLink>
          </Box>
        </Hidden>
        {auth.isAuthenticated ? (
          <Box sx={{ p: 2 }}>
            <Box
              sx={{
                alignItems: "center",
                backgroundColor: "background.default",
                borderRadius: 1,
                display: "flex",
                overflow: "hidden",
                p: 2,
              }}
            >
              <RouterLink to="/account">
                <Avatar
                  src={UserData ? UserData.photoURL : null}
                  sx={{
                    cursor: "pointer",
                    height: 48,
                    width: 48,
                  }}
                />
              </RouterLink>
              <Box sx={{ ml: 2 }}>
                <Typography color="textPrimary" variant="subtitle2">
                  {UserData ? UserData.displayName : "Demo User"}
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  Your plan:{" "}
                  <Link
                    color="primary"
                    component={RouterLink}
                    to="/account#subscription"
                  >
                    {UserData ? UserData.membership : "Basic"}
                  </Link>
                </Typography>

                <Typography color="textSecondary" variant="subtitle2">
                  {UserData ? UserData.userType : null}
                </Typography>
              </Box>
            </Box>
          </Box>
        ) : null}
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections(
            UserHasDeals && auth.isAuthenticated,
            UserHasApplications && auth.isAuthenticated,
            UserData ? UserData.userType : null
          ).map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
        {!auth.isAuthenticated ? <RegisterLoginBox /> : null}
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              width: 280,
            },
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              height: "calc(100% - 64px) !important",
              top: "64px !Important",
              width: 280,
            },
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
