/*eslint no-constant-condition: ["error", { "checkLoops": false }]*/
import { experimentalStyled } from "@material-ui/core/styles";
import Footer from "components/Footer";
import useAuth from "hooks/useAuth";
import { scrollTo } from "internal/LayoutFunctions";
import { getMockAuthor } from "internal/MockData";
import { useSnackbar } from "notistack";
import "pages/dashboard/styles.css";
import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import wait from "utils/wait";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";

const DashboardLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DashboardLayoutWrapper = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: "64px",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: "280px",
  },
}));

const DashboardLayoutContainer = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const DashboardLayoutContent = experimentalStyled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch",
});

const DashboardLayout = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const location = useLocation();

  var myRef = useRef(null);

  useEffect(async () => {
    scrollTo(myRef.current, 0, 0.2);
  }, [location.pathname]);

  useEffect(async () => {
    if (!auth.isAuthenticated) {
      await wait(20000);

      enqueueSnackbar("🙌 " + getMockAuthor().name + " just registered 🎉 ", {
        variant: "default",
        disableWindowBlurListener: true,
        autoHideDuration: 7000,
      });
    }

    if (!auth.isAuthenticated) {
      await wait(30000);
      enqueueSnackbar("🙌 " + getMockAuthor().name + " just registered 🎉 ", {
        variant: "default",
        disableWindowBlurListener: true,
        autoHideDuration: 7000,
      });
    }
  }, []);

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar
        onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)}
      />
      <DashboardSidebar
        onMobileClose={() => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent ref={myRef}>
            <Outlet />
            <Footer />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
