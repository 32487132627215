import {
  getRandomNumber,
  getRandomNumberFloor,
  getRandomNumberFloorMax,
} from "internal/Calculation.js";

import { createUUID } from "internal/LayoutFunctions";
import { subMinutes } from "date-fns";

const now = new Date();

export const getMockCollaboratorObject = () => {
  let collaborator = getMockCollaborator();
  return {
    id: createUUID(),
    avatar: collaborator.avatar,
    job: collaborator.job,
    name: collaborator.name,
  };
};

export const getMockCollaborator = () => {
  let arrayElementNumber = getRandomNumberFloorMax(MockCollaborators.length);
  return MockCollaborators[arrayElementNumber];
};

export const getMockReview = () => {
  let author = getMockAuthor();
  return {
    id: createUUID(),
    author: {
      avatar: author.avatar,
      name: author.name,
    },
    comment: getMockReviewComment(),
    createdAt: subMinutes(now, getRandomNumberFloor(20, 50)).getTime(),
    value: getRandomNumber(4.5, 5),
  };
};
// todo: Get randomNumberFloor for getRandomNumberFloor

export const getMockReviewComment = () => {
  let arrayElementNumber = getRandomNumberFloorMax(MockComments.length);
  return MockComments[arrayElementNumber];
};

export const getMockAuthor = () => {
  let arrayElementNumber = getRandomNumberFloorMax(MockAuthors.length);
  return MockAuthors[arrayElementNumber];
};

export const MockComments = [
  "Great company, providing an awesome & easy to use product.",
  "I applied and they shipped the product right away. I am an influencer with 50k subs on Instagram.",
  "I can only say good thing about this company, good payment and nice products. Everything as it should be.",
  "Happy that I applied to this deal. Nice people there :)",
  "Gladly again",
  "Can wait for their next deal",
  "Had to cancel my submission, but they weren't mad about it",
  "We had so much fun on the set",
  "Collaboration was a total succes, happy to give them a review",
  "Please keep doing brand deals! ",
  "To my fellow influencers friends: Go get it, it was an awesome experience",
  "Definitely should try this",
];

export const MockAuthors = [
  {
    avatar: "/static/mock-images/avatars/avatar-marcus_finn.png",
    name: "Marcus Finn",
  },
  {
    avatar: "/static/mock-images/avatars/avatar-miron_vitold.png",
    name: "Miron Vitold",
  },
  {
    avatar: "/static/mock-images/avatars/avatar-carson_darrin.png",
    name: "Carson Darrin",
  },
  {
    avatar: "/static/mock-images/avatars/avatar-nasimiyu_danai.png",
    name: "Nasimiyu Danai",
  },
  {
    avatar: "/static/mock-images/avatars/avatar-neha_punita.png",
    name: "Caroline Lutz",
  },
  {
    avatar: "/static/mock-images/avatars/avatar-iulia_albu.png",
    name: "Jessy Rockberg",
  },
  {
    avatar: "/static/mock-images/avatars/avatar-jane_rotanson.png",
    name: "Jane Rosenthal",
  },

  {
    avatar: "/static/mock-images/avatars/lindsey.jpeg",
    name: "Lindsey H.",
  },
  {
    avatar: "/static/mock-images/avatars/avatar-jie_yan_song.png",
    name: "Jie Yan S.",
  },
  {
    avatar: "/static/mock-images/avatars/avatar-alcides_antonio.png",
    name: "Alcides Antonio.",
  },
  {
    avatar: "/static/mock-images/avatars/avatar-chinasa_neo.png",
    name: "Chinasa Neo",
  },
  {
    avatar: "/static/mock-images/avatars/avatar-fran_perez.png",
    name: "Fran Perez",
  },
  {
    avatar: "/static/mock-images/avatars/avatar-penjani_inyene.png",
    name: "Penjani Inyene",
  },
  {
    avatar: "/static/mock-images/avatars/avatar-seo-hyeon_ji.png",
    name: "Seo Hyeon Ji",
  },

  {
    avatar: "/static/mock-images/avatars/avatar_lisa_hensel.jpg",
    name: "Lisa Hensel",
  },
  {
    avatar: "/static/mock-images/avatars/avatar_sophia_brag.jpg",
    name: "Sophia Brag",
  },
  {
    avatar: "/static/mock-images/avatars/avatar_maria_cortez.jpg",
    name: "Maria Cortez",
  },

  {
    avatar: "/static/mock-images/avatars/avatar_gil_stanley.jpg",
    name: "Gil Stanley",
  },

  {
    avatar: "/static/mock-images/avatars/avatar_mia_herbert.jpg",
    name: "Mia Herbert",
  },
];

export const MockCollaborators = [
  // {
  //   avatar: "/static/mock-images/avatars/abbie_herbert.jpg",
  //   job: "Supporting Proud Mamas",
  //   name: "Abbie Herbert",
  // },
  // {
  //   avatar: "/static/mock-images/avatars/helen_owen.jpg",
  //   job: "Travel + Fashion",
  //   name: "Helen Owen",
  // },
  {
    avatar: "/static/mock-images/avatars/avatar_lisa_rodriguez.jpg",
    job: "Leaving a bit of a sparkle everywhere I go :)",
    name: "Lisa Rodriguez",
  },
  {
    avatar: "/static/mock-images/avatars/avatar_sophia_and_tobias.jpg",
    job: "Coffee Addicts, going anywhere anytime",
    name: "Sophia&Tobias",
  },
  {
    avatar: "/static/mock-images/avatars/avatar_emilia_sanchez.jpg",
    job: "Girls can do anything!",
    name: "Emilia Sanchez",
  },
  {
    avatar: "/static/mock-images/avatars/avatar_christina_stanley.jpg",
    job: "Fashion/Beauty Influencer",
    name: "Christina Stanley",
  },
  {
    avatar: "/static/mock-images/avatars/avatar_zoe_heather.jpg",
    job: "Never too old to travel",
    name: "Zoe Heather",
  },
  // {
  //   avatar: "/static/mock-images/avatars/pamela_reif.jpg",
  //   job: "Food, Fashion and Lifestyle",
  //   name: "Pamela Reif",
  // },

  {
    avatar: "/static/mock-images/avatars/avatar_lisa_hensel.jpg",
    job: "Beauty, Nature and Travel",
    name: "Lisa Hensel",
  },
  {
    avatar: "/static/mock-images/avatars/avatar_sophia_brag.jpg",
    job: "Fashion and Love 🏖️",
    name: "Sophia Brag",
  },
  {
    avatar: "/static/mock-images/avatars/avatar_maria_cortez.jpg",
    job: "I love what I do because I influence you 💖",
    name: "Maria Cortez",
  },

  {
    avatar: "/static/mock-images/avatars/avatar_gil_stanley.jpg",
    job: "Let's do this girl 👏",
    name: "Gil Stanley",
  },

  {
    avatar: "/static/mock-images/avatars/avatar_mia_herbert.jpg",
    job: "My passion is bigger than yours 🤭",
    name: "Mia Herbert",
  },
];
